import React, {useState} from 'react';
import Radium from 'radium';
import MailForm from "../components/mailForm.js"


const Pisarna = () => {

	const [text, setText] = useState({
		open1: false
	})

	function handleClick1(){
		setText({open1: !text.open1,
			boxTitle:"Employment",
			boxText1:"The firm advises in different situations for the employers on a daily basis, Drafting Internal organisationalk documents, representing them in administrative procedures (Inpectorate) and employment litigation. We also provide our clients with collective nagotiation with trade unions. ",
			boxText2:"",
			boxText3:"We also advise and represent clients in social rights enformcement procedures.",
			boxText4:"",
			boxText5:"",
			boxText6:"",
			boxText7:"",
			boxText8:"",
			boxText9:"",
			boxText10:"",
			boxText11:"",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick2(){
		setText({open1: !text.open1,
			boxTitle:"Construction Law",
			boxText1:"As law firm specialised in construction and infrastructure projects we advise our clients on all aspects of construction law, including documentation preparation (such as permits in administrative procesdure), contracts, negotiations, litigation and we also specialize in FIDIC International Contraction Contracts.",
			boxText2:"",
			boxText3:"",
			boxText4:"",
			boxText5:"",
			boxText6:"",
			boxText7:"",
			boxText8:"",
			boxText9:"",
			boxText10:"",
			boxText11:"",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick3(){
		setText({open1: !text.open1,
			boxTitle:"Real Estate",
			boxText1:"We offer legal advice to individuals and business as to all aspects of real estate, including acquisition, sale and gifts, and real estate finance, construction, and operation. We can help record title, draft agreements, advise on adverse possession, easements, and permitting, including zoning, variance, and other administrative permits.",
			boxText2:"",
			boxText3:"",
			boxText4:"",
			boxText5:"",
			boxText6:"",
			boxText7:"",
			boxText8:"",
			boxText9:"",
			boxText10:"",
			boxText11:"",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick4(){
		setText({open1: !text.open1,
			boxTitle:"Corporate and Commercial Law",
			boxText1:"We advise clients on a wide range of corporate law matters required for effective market integration in different industries.",
			boxText2:"",
			boxText3:"",
			boxText4:"",
			boxText5:"",
			boxText6:"",
			boxText7:"",
			boxText8:"",
			boxText9:"",
			boxText10:"",
			boxText11:"",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick5(){
		setText({open1: !text.open1,
			boxTitle:"Health & Safety ",
			boxText1:"We advise and represent clients in full range of workplace matters, from risk management to intervetion in a crisis and through the defense of resulting claims and we also specialise in work place investigations and claims, supporting the client with our team of legal experts and also other experts to present the client with best possible solution.",
			boxText2:"",
			boxText3:"",
			boxText4:"",
			boxText5:"",
			boxText6:"",
			boxText7:"",
			boxText8:"",
			boxText9:"",
			boxText10:"",
			boxText11:"",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick6(){
		setText({open1: !text.open1,
			boxTitle:"Infrastructure, Public procurement & Public private partnership.",
			boxText1:"The firm advises clients on different infrastructure projucts, either as public procurement or public private partnership projects, also promoters, investorst and public enteties involved in projects related to infrastructure projects and construction works in differnet areas, such as sports infrastructure, reads, medical centers, nursing homes, waste management, energy sector, based on sustainability.",
			boxText2:"",
			boxText3:"",
			boxText4:"",
			boxText5:"",
			boxText6:"",
			boxText7:"",
			boxText8:"",
			boxText9:"",
			boxText10:"",
			boxText11:"",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	const box_info_absolute_wrapper_state1 = text.open1 ? styles.box_info_absolute_wrapper_open1 : styles.box_info_absolute_wrapper_close1;

	return(
		<div style={styles.wrapper}>
			<div style={styles.container}>
				<div style={styles.content_1}>
					<div style={styles.naslov}>
						Core Areas
					</div>

				</div>
				<div style={styles.content_2_flexWrapper}>
					<div key="1" style={[styles.box_info_absolute_wrapper, box_info_absolute_wrapper_state1]}>
						<div style={styles.box_info_absolute}>
							<div style={styles.box_info_absolute_close_button_wrapper} onClick={handleClick1}>
								<img alt="ikona_vec_close" style={styles.close_button}
										 src="../assets/icons/ikona_vec_close.svg" />
							</div>
							<div style={styles.box_info_absolute_title}> {text.boxTitle}</div>
							<div style={styles.boxAbsoluteFlex}>
								<div key="1" style={styles.boxAbsoluteFlexRow} >

									<div key="1" style={styles.box_info_absolute_text}>{text.boxText1}</div>
									<div key="2" style={styles.box_info_absolute_text}>{text.boxText2}</div>
									<div key="3" style={styles.box_info_absolute_text}>{text.boxText3}</div>
									<div key="4" style={styles.box_info_absolute_text}>{text.boxText4}</div>
									<div key="5" style={styles.box_info_absolute_text}>{text.boxText5}</div>
									<div key="6" style={styles.box_info_absolute_text}>{text.boxText6}</div>
									<div key="7" style={styles.box_info_absolute_text}>{text.boxText7}</div>
									<div key="8" style={styles.box_info_absolute_text}>{text.boxText8}</div>
								</div>

							</div>
						</div>
					</div>
					<div key="2" onClick={handleClick2} style={styles.content_2_flexWrapper_box}>


						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Construction Law
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="3" onClick={handleClick4} style={[styles.content_2_flexWrapper_box, styles.content_2_flexWrapper_box_secondary]}>


						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Corporate and Commercial Law
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="4" onClick={handleClick1} style={styles.content_2_flexWrapper_box}>

						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Employment
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="5" onClick={handleClick5} style={[styles.content_2_flexWrapper_box, styles.content_2_flexWrapper_box_secondary]}>

						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Health & Safety
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="6" onClick={handleClick6} style={styles.content_2_flexWrapper_box}>


						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Infrastructure, <br />
								Public procurement & Public private partnership.
							</div>
							<div style={styles.content_2_flexWrapper_box_more}>
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="7" onClick={handleClick3} style={[styles.content_2_flexWrapper_box, styles.content_2_flexWrapper_box_secondary]}>

						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Real Estate
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
				</div>
				<div style={styles.content_3}>
					<div key="1" style={styles.naslov}>
						Other Areas
					</div>
					<div key="2" style={styles.podnaslov}>
						Consumer Protection
					</div>
					<div key="3" style={styles.besedilo}>
						We have domestic and international expertise in consumer protection. We offer legal advice to businesses and other private and public sector entities to ensure compliance with comsumer protection laws and regulations, including privacy. We offer assistance to consumers, including related to online and distance purchases, and flight delay and cancellation compensation under EU law. We help clients enforce rights related to the receipt of noncoforming goods and breach of warranty, unfair or unconscionable dealing and consumer credit. We collaborate with foreign and international consumer protection organizations because many consumers make purchases in the EU and elsewhere.
					</div>
					<div key="4" style={styles.podnaslov}>Tort Law</div>
					<div key="5" style={styles.besedilo}>Our law firm currently advises and represents clients in tort actions, primarily related to workplace and occupational injuries and conditions. We represent both plaintiffs and defendants. To employers, we offer a comprehensive review and a recommendation of necessary measures to ensure workplace and occupational safety, in particular in the aftermath of an incident.</div>

					<div key="6" style={styles.podnaslov}>Inheritance</div>
					<div key="7" style={styles.besedilo}>We offer legal advice on inheritance law, including drafting life care contracts and delivery of property or gifts. We represent clients in inheritance proceedings and draft wills.</div>

					<div key="8" style={styles.podnaslov}>Family Law</div>
					<div key="9" style={styles.besedilo}>With extensive experience in ​​family law, we offer legal advice related to divorce, separation and dissolution of civil unions. In doing so, we can advise on both property division between spouses or partners and on custody of minor children, child support and visitation. </div>

					<div key="10" style={styles.podnaslov}>Misdemeanors</div>
					<div key="11" style={styles.besedilo}>We represent both legal and natural persons in administrative and court proceedings ranging from defending against speeding tickets to enforcement proceedings before the Financial Administration of the Republic of Slovenia (i.e., Slovenian tax authority).</div>

					<div key="12" style={styles.podnaslov}>Real Estate Management</div>
					<div key="13" style={styles.besedilo}>cost calculations for multifamily buildings, drafting terms and conditions for contracts to supply heating, recovering unpaid utilities from occupants of individual units, and other related services. </div>


				</div>
			</div>
			<MailForm />
			<div style={styles.picture}>
				<div style={styles.picture_container}>
					We are building long term relationships.
				</div>
			</div>
		</div>
	)
}




const styles = {
	wrapper:{

		position:"relative",
		backgroundColor:"#fff",
		paddingTop:70,
		'@media (max-width: 630px)': {
			paddingTop: 40
	    },
	},
	container:{
		width: "calc(100% - 60px)",
		margin: "0px auto",
		positon: "relative",
	},
	content_1:{
		maxWidth:950,
		//height: "30vh",
		margin: "20px auto",
		marginBottom:60,

	},
	naslov:{
		fontSize: 41,
		fontWeight: 300,
		marginBottom: 30,
		color: "#243353",
		'@media (max-width: 800px)': {
			fontSize: 35,
			marginBottom: 20
	    },
	    '@media (max-width: 670px)': {
			textAlign: "center"
	    },
	    '@media (max-width: 520px)': {
			fontSize: 30,
	    },

	},





	//box_info

	box_info_absolute:{
		color:"#fff",
	},
	box_info_absolute_wrapper:{
		position:"absolute",
		top:-1,
		left:0,
		zIndex:2,
		padding:20,
		borderRadius:4,
		backfaceVisibility: "hidden",
    transform: "translateZ(0) scale(1.0, 1.0)",

		width:"calc(100% - 0px)",
		height:"calc(100% - 57px)",
		background:"#F89C2E",
		transition:"0.2s",
		'@media (max-width: 915px)': {
			height:"calc(100% - 77px)",
		},
		'@media (max-width: 655px)': {
			padding:0,
			width:"calc(100% + 2px)",
			height:"calc(100% + 2px)",
		},
		'@media (max-width: 420px)': {
			height:"calc(100% + 2px)",
		},
	},
	box_info_absolute_title:{
		fontSize:37,
		margin:30,
		marginBottom:0,
		marginTop:10,
		'@media (max-width: 1200px)': {
			fontSize: 30
		},
		'@media (max-width: 1060px)': {
			fontSize: 25,
			marginTop:0,
		},
		'@media (max-width: 670px)': {
			marginBottom:40,
			marginTop:80,
		},
		'@media (max-width: 350px)': {
			fontSize: 20
		},
		
	},
	box_info_absolute_text:{
		fontSize:16,
		marginBottom:19,
		fontWeight:500,
		lineHeight:1.4,

		'@media (max-width: 1000px)': {
			fontSize: 15,
			marginBottom:9,

		},
		'@media (max-width: 700px)': {

		},
		'@media (max-width: 670px)': {
			lineHeight:1.3,
			marginBottom:12,
		},
		'@media (max-width: 370px)': {
			marginBottom:10,
		},
	},


	box_info_absolute_wrapper_open1: {
		transform:"translateY(0%) scale(1,1)",
	},
	box_info_absolute_wrapper_close1: {
		transform:"translateY(-50%) scale(1,0)",
	},



	box_info_absolute_close_button_wrapper:{
		position:"absolute",
		zIndex:10000,
		top:0,
		right:30,
		backfaceVisibility: "hidden",
        transform: "translateY(0) rotate(45deg)",
        cursor:"pointer",
    '@media (max-width: 670px)': {
			top:-50,
		},
	},
	close_button:{
		cursor:"pointer",
		height:35,
	},


	boxAbsoluteFlex:{
		display:"flex",
		'@media (max-width: 670px)': {
			flexDirection:"column",
		},
	},
	boxAbsoluteFlexRow:{
		margin:30,
		width:"calc(100% - 60px)",
		'@media (max-width: 670px)': {
			margin:30,
			marginTop:0,
			marginBottom:0,
			width:"calc(100% - 60px)",
		},
	},



	//end box_info



	podnaslov:{
		fontSize: 22,
		marginBottom: 15,
		marginTop: 20,
		color: "#243353",
		'@media (max-width: 800px)': {
			fontSize: 22
	    },
	    '@media (max-width: 670px)': {
			textAlign: "center"
	    },
	    '@media (max-width: 500px)': {
			marginTop: 30
	    },
	},
	besedilo:{
		lineHeight:1.4,
		letterSpacing:0.6,
		fontSize: 17,
		fontWeight: 300,
		color: "#243353",
		'@media (max-width: 800px)': {
			fontSize: 16
	    },
	    '@media (max-width: 670px)': {
			textAlign: "center"
	    },
	    '@media (max-width: 400px)': {
			fontSize: 14
	    },
	},

	content_2_flexWrapper:{
		margin: "0px auto",
		maxWidth:950,
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "space-between",
		marginBottom: 20,
		'@media (max-width: 670px)': {
			flexFlow: "column wrap",
			alignItems: "center"
	    },
	    '@media (max-width: 340px)': {
			
	    },
	},
	content_2_flexWrapper_box:{
		width: "47%",
		display:"flex",
		alignItems:"center",
		justifyContent:"center",
		position: "relative",
		height:100,
		cursor:"pointer",
		border: "1px solid #243353",
		borderRadius: 4,
		marginBottom: 60,
		transition:"0.1s",
		'@media (max-width: 670px)': {
			width: "90%",
			marginBottom: 40,
	    },
	    '@media (max-width: 450px)': {
				width: "100%",
	    },
	    ":hover":{
	    	border: "4px solid #243353",
	    	height:100,
	    	borderRadius: 8,
	    	transform: "scaleX(1.05) scaleY(1.05)",
	    },

	},

	content_2_flexWrapper_box_secondary:{
		'@media (max-width: 670px)': {
			width: "90%",
			order: 2
	    },
	    '@media (max-width: 450px)': {
			width: "100%",
	    },
		
	},
	content_2_flexWrapper_box_padding:{
		display:"flex",
		cursor:"pointer",
		alignItems:"center",
		justifyContent:"space-between",
		width: "90%",
		
	},
	content_2_flexWrapper_box_padding_TR:{
		'@media (max-width: 820px)': {
			paddingTop: "6%",
			paddingBottom: "6%"
		},
		'@media (max-width: 670px)': {
			paddingTop: "7%",
			paddingBottom: "7%"
	    },
	    '@media (max-width: 525px)': {
			paddingTop: "5.5%",
			paddingBottom: "5.5%"
	    },
	},
	content_2_flexWrapper_box_info:{
		position: "absolute",
		display: "none"
	},
	content_2_flexWrapper_box_text:{
		display: "inline-block",
		fontSize: 20,
		color: "#243353",
		'@media (max-width: 885px)': {
			fontSize: 18,
			marginRight: 10
	    },
	    
	},

	content_2_flexWrapper_box_more:{
		display: "inline-block",
		cursor:"pointer",
	},
	content_3:{
		maxWidth:950,
		
		//height: "40vh",
		margin: "0px auto",
		marginBottom: 80,
		'@media (max-width: 550px)': {
			marginBottom: 40,
		},
	},
	
	
	contacts_form_inputWraper_design:{
		borderRadius: 4,
		width: "28%",
		padding: "10px 0px 10px 15px",
		display: "inline-block",
		position: "relative",
		left: -13,
		backgroundColor: "#FFF",
		'@media (max-width: 1150px)': {
			padding: "5px 0px 5px 15px",
			},
			'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px"
			},
			'@media (max-width: 820px)': {
			width: "32%"
			},
			'@media (max-width: 700px)': {
			display: "block",
			margin: "0px auto",
			left: 0,
			marginBottom: 10,
			width: "40%"
			},
			'@media (max-width: 600px)': {
			width: "50%"
			},
			'@media (max-width: 450px)': {
			width: "60%"
			},
			'@media (max-width: 370px)': {
			width: "75%"
			},
	},
	contacts_form_input:{
		fontSize: 19,
		fontWeight: 300,
		border: "none",
		borderLeft: "2px solid #F5A623",
		padding: "10px 0px 10px 15px",
		width: "95%",
		outline: "none",
		'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px",
			fontSize: 17,
			},	
			'@media (max-width: 820px)': {
			padding: "3px 0px 3px 10px",
			fontSize: 15,
			},
	},

	/*prijava_input:{
		borderRadius: 4,
		fontSize: 19,
		fontWeight: "300",
		width: "29%",
		padding: "17 0 17 40",
		position: "relative",
		left: -20,
		outline: "none",
		boxSizing: "border-box",
		borderLeft: "none"
	},*/
	

	picture:{
		height: "55vh",
		maxHeight:500,
		backgroundSize: "cover",
		backgroundImage: "url(../assets/pictures/Group.png)",
		borderBottom: "7px solid #fff",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		'@media (max-width: 750px)': {
			height: "45vh",
	    },
	    '@media (max-width: 600px)': {
			height: "40vh",
	    },
	},
	picture_container:{
		width: "38%",
		fontSize: 41,
		fontWeight: 300,
		color: "#162A75",
		textAlign: "center",
		'@media (max-width: 1175px)': {
			width:"50%"
	    },
	    '@media (max-width: 1000px)': {
			fontSize: 35
	    },
	    '@media (max-width: 750px)': {
			fontSize: 30
	    },
	    '@media (max-width: 600px)': {
			fontSize: 25,
			width: "70%"
	    },
	},
	


};



export default Radium(Pisarna);