import React from 'react'
import Radium from 'radium';


class PrivacyPage extends React.Component {

	render(){
		return(
			<div style={styles.wrapper}>
				<div style={styles.wrapperInner}>
					<div style={styles.title}>Splošni pogojih uporabe in politika varstva osebnih podatkov</div>

					<div style={styles.subtitle}>1. Področje uporabe</div>
					<div style={styles.text}>Ti Splošni pogoji uporabe in politika varstva osebnih podatkov se nanašajo na vse uporabnike in vse oblike uporabe spletne strani www.glusic.si, ki je v lasti Odvetniške družbe Glušič o.p., d.o.o. ter določajo pogoje njene uporabe ter politiko varstva osebnih podatkov, ki je sledimo. Uporabljeni pojmi: 
						<div>1.	"splošni pogoji" pomenijo te Splošne pogoje uporabe in politiko varstva osebnih podatkov;</div>
						<div>2.	"spletna stran" pomeni spletno stran, spletne dejavnosti in ostale spletne oziroma digitalne storitve oziroma aplikacije odvetniške družbe Glušič www.glusic.si;</div>
						<div>3.	"uporabnik" predstavlja vsakega uporabnika oziroma obiskovalca spletne strani, </div>
						<div>4.	"Odvetniška družba Glušič" ali "odvetniška družba" pomeni Odvetniško družbo Glušič o.p., d.o.o., Pod Javorji 6, PC Žeje pri Komendi, 1218 Komenda.</div>

						<div style={styles.subtitle}>2.Seznanjenost s splošnimi pogoji</div>
						<div style={styles.text}>Uporabnik potrjuje, da je z vstopom na spletno stran izvajalca skrbno prebral te Splošne pogoje uporabe spletne strani in da se z njimi strinja ter da jih sprejema v celoti. Če s pogoji uporabe ne soglašate, priporočamo, da te spletne strani ne uporabljate in jo zapustite.</div>

						<div style={styles.subtitle}>3.Uporaba spletne strani</div>
						<div style={styles.text}>Podatki objavljeni na spletni strani in vsebine so namenjeni izključno informativni naravi. Z objavami in na podlagi objav za Odvetniško družbo ne nastanejo nikakršne pravice ali obveznosti, razen če je to izrecno navedeno. </div>
						<div style={styles.text}>Uporaba spletne strani je na lastno odgovornost. </div>
						<div style={styles.text}>Ves čas si prizadevamo točnost in pravilnost podatkov, objavljenih na spletni strani. Za morebitne jezikovne ali vsebinske pomanjkljivosti, točnost informacij, tehnične ali druge napake ali za pomanjkljivo ažurirane podatke na spletni strani, ne odgovorajamo. Če na spletni strani obstajajo povezave na druge spletne strani, ki niso v nikakršni povezavi z odvetniško družbo, ne prevzemamo nobene odgovornosti za obliko in vsebino podatkov, pridobljenih prek povezav, ki niso last izvajalca ter za politike varovanja podatkov in pravil uporabe teh strani.</div>


						<div style={styles.text}>Odvetniška družba ne jamči, da bodo spletna stran ali njene podstrani (vključno z vsemi vsebinami) vedno na voljo, da bo uporaba spletne strani varna in neprekinjena. Odvetniška družba ni odgovorna za mrežne in telefonske povezave, vključno s pokritostjo območja ali prekinitvijo zveze, kot tudi ne za kakršno koli škodo, ki bi nastala zaradi uporabe spletne strani, kot so izgubljeni podatki, izguba programov ali za kakršno koli podobno škodo oziroma za škodo, ki bi izhajala iz uporabe, obstoja ali nemožnosti uporabe spletne strani ali vsebin objavljanih na spletni strani. </div>
						<div style={styles.text}>Odvetniška družba ne odgovarja za zlorabe tretjih oseb oziroma uporabnikov.</div>


						<div style={styles.subtitle}>4.Avtorske pravice</div>
						<div style={styles.text}>Vse materialne in moralne avtorske pravice vseh avtorskih del objavljenih na spletni strani pripadajo Odvetniški družbi Glušič. Avtorska dela so: prispevki, besedila, fotografije, razporeditve, design, video, glasba, ilustracije, grafični elementi, znaki in znamke in podobno). Imetniki avtorske pravice na avtorskih delih objavljenih na spletni strani so lahko tudi zaposleni v  Odvetniški družbi, partnerji, pogodbeni sodelavci ali tretje osebe, kar je ustrezno označeno. Odvetniška družba dovoljuje, da se vsebina spletne strani, kjer so avtorska dela, le prebira in pregleduje. Odvetniška družba prepoveduje uporabo njenih avtorskih del v vseh oblikah (na primer: spreminjanje, javno prikazovanje, predvajanje, reprodukcijo), kakor tudi uporabo podatkov navedenih v teh delih, brez predhodno pridobljenega dovoljenja Odvetniške družbe Glušič. Kadar je podano dovoljenje mora uporabnik jasno označiti, da gre za avtorsko delo Odvetniške družbe Glušič in navesti vir pridobitve. </div>


						<div style={styles.subtitle}>5.Politika varstva osebnih podatkov</div>
						<div style={styles.subtitle}>5.1.Splošno</div>
						<div style={styles.text}>Ob obisku spletne strani www.glusic.si ter njenih podstrani se na spletnem strežniku avtomatsko shranjuje dnevniška datoteka spletnega strežnika (npr. IP-številka - številka, ki identificira posamezni računalnik oziroma drugo napravo, povezano s spletom; različica brskalnika, čas obiska in podobno). Podrobneje je politika piškotkov opisana v Politiki piškotkov objavljeni <a href="../assets/Politika_piskotkov.pdf" download="Politika_piskotkov.pdf">tukaj</a>. Te podatke obdelujemo za potrebe vodenja svoje statistike obiskov na spletni strani. </div>
						<div style={styles.text}>Osebni podatki na spletni strani se zbirajo izključno za namene določene v teh Splošnih Pogojih in <a href="../assets/Politika_piskotkov.pdf" download="Politika_piskotkov.pdf">Politiki Piškotkov</a>, za katere se strinjate, da ste jih prebrali. Varovanje osebnih podatkov se zagotavlja skladno z zakonom, ki ureja varstvo osebnih podatkov, zakonom, ki ureja elektronske komunikacije in Splošno uredbo o varstvu osebnih podatkov (GDPR). </div>
						<div style={styles.text}>Vse osebne podatke, ki jih posredujete, bo Odvetniška družba obravnavala zaupno in jih uporabila zgolj v namene, za katere ste jih posredovali. Če bo nastala potreba po nadaljnji obdelavi vaših podatkov za kak drug namen, vas bomo o tem predhodno obvestili in vas zaprosili za vaše soglasje.</div>
						<div style={styles.text}>Če na spletni strani obstajajo povezave na druge spletne strani, ki niso v nikakršni povezavi z Odvetniško družbo Glušič, ne prevzemamo nobene odgovornosti za zaščito vaših osebnih podatkov na teh spletnih straneh. </div>
						<div style={styles.text}>Za pravilnost in točnost osebnih podatkov, ki jih preko spletne strani www.glusic.si pošljte Odvetniški družbi Glušič ste odgovorni izključno sami in nam jih posredujete prostovoljno in na podlagi privolitve. </div>
						<div style={styles.text}>Posredovanje osebnih podatkov ni pogoj za uporabo spletne strani www.glusic.si.</div>



						<div style={styles.subtitle}>5.2.E-novice</div>
						<div style={styles.text}>V naši odvetniški družbi se trudimo uporabnikom podati aktualne informacije iz različnih področij, povezanih s pravno stroko preko objav novic, člankov, mnenj, priporočil, informacij. Te informacije so zgolj informativne narave in so za odvetniško družbo nezavezujoče. </div>
						<div style={styles.text}>Na naši spletni strani ali z izpolnitvijo fizičnih obrazcev se lahko naročite na prejemanje novic ter informacij iz različnih pravnih področij, ki vas utegnejo zanimati in s katerimi se ukvarja Odvetniška družba Glušič ter na obvestila o izobraževanjih in delavnicah, na katerih sodelujejo odvetniki, odvetniški kandidati in drugi sodelavci Odvetniške družbe Glušič, novice o Odvetniški družbi Glušič ali drugih pomembnih dogodkih povezanih s področjem prava, in sicer tako, da nam posredujete ime in priimek ter e- naslov. V primeru prijave vas lahko Odvetniška družba Glušič prek tega e-naslova obvešča o novih pravnih nasvetih, novicah, publikacijah, predavanjih in izobraževanjih, ter vam posreduje druge informacije s področja pravnih vprašanj, ki so aktualna in so del delovanja Odvetniške družbe Glušič.</div>
						<div style={styles.text}>Navedeni osebni podatki se obdelujejo na osnovi vašega soglasja, pri čemer lahko soglasje za prejemanje oglasnih sporočil kadar koli prekličete. </div>
						
						<div style={styles.subtitle}>5.3.Piškotki</div>

						<div style={styles.text}>Spletna stran www.glusic.si uporablja piškotke, kar je opredeljeno v <a href="../assets/Politika_piskotkov.pdf" download="Politika_piskotkov.pdf">Politiki Piškotkov</a>. Svetujemo, da se s Politiko piškotkov seznanite. </div>


						<div style={styles.subtitle}>5.4.Uporabniki podatkov</div>
						<div style={styles.text}>Odvetniška družba Glušič bo podatke primarno uporabljala za namene opredeljene v teh Splošnih pogojih. Zavezujemo pa se, da pridobljenih osebnih podatkov ne bomo posredovali tretjim osebam. Vendar pa Odvetniška družba lahko zbrane podatke posreduje pogodbenim obdelovalcem z namenom zagotavljanja nekaterih storitev (na primer: administratorju in skrbniku spletne strani). Zavezujemo se, da vaših osebnih podatkov ne bomo posredovali ali prenesli v tretjo državo ali kaki mednarodni organizaciji.</div>




						<div style={styles.subtitle}>5.5.Čas hrambe</div>
						<div style={styles.text}>Vaše osebne podatke hranimo toliko časa, kolikor je potrebno, da se izpolni namen za katerega so bili zbrani in uporabljeni, in sicer se vaši kontaktni podatki za potrebe prejemanja e-novic hranijo do preklica. Čas hrambe za podatke pridobljene preko piškotkov in njihove uporabnike pa je razvidno iz <a href="../assets/Politika_piskotkov.pdf" download="Politika_piskotkov.pdf">Politiki Piškotkov</a>.</div>
						<div style={styles.text}>Po izteku časa in skladno s tehničnimi možnostmi se osebni podatki izbrišejo.</div>


						<div style={styles.subtitle}>5.6.Pravice uporabnika</div>
						<div style={styles.text}>Odvetniška družba Glušič zagotavlja uresničevanje vseh pravic uporabnikov v zvezi z varstvom osebnih podatkov skladno z veljavnimi predpisi. </div>
						<div style={styles.text}>Od Odvetniške družbe lahko kadar koli zahtevate:
							<div>-	podatek o tem, ali se v zvezi z vami obdelujejo osebni podatki;</div>
							<div>-	dostop do osebnih podatkov (vpogled in/ali prepis);</div>
							<div>-	informacije v zvezi z obdelavo teh podatkov (namen obdelave, vrsta podatkov, uporabnikih podatkov, času hrambe);</div>
							<div>-	kopijo osebnih podatkov;</div>
							<div>-	popravek netočnih osebnih podatkov in dopolnitev nepopolnih osebnih podatkov;</div>
							<div>-	izbris vseh osebnih podatkov ("pravica do pozabe");</div>
							<div>-	omejitve obdelave osebnih podatkov;</div>
							<div>-	prenos podatkov;</div>
							<div>-	preklic privolitve.</div>
						</div>
						<div style={styles.text}>Vaše pravice se izvajajo skladno z vsakokratno veljavnimi predpisi. </div>
						
						

						<div style={styles.subtitle}>6.	Sprememba pogojev in kršenje pogojev</div>
						<div style={styles.text}>Splošni pogoji se lahko spreminjajo brez izrecnega predhodnega opozorila, zato uporabnikom svetujemo, da so na morebitne spremembe pozorni, da bodo seznanjeni in obveščeni o veljavnih pogojih uporabe spletne strani ter podatki o varovanju zasebnosti. Za kakršne koli posledice sprememb Splošnih pogojev odvetniška družba ne prevzema nobene odgovornosti. Na spletnih straneh bodo objavljeni vsakokratno veljavni splošni pogoji, sprejeti skladno z vsakokratno veljavnimi predpisi. Zadnja veljavna verzija splošnih pogojev je označena z navedbo datuma, od kdaj splošni pogoji veljajo na koncu dokumenta. </div>



						<div style={styles.subtitle}>7.	Kontakt in prijava kršitev</div>
						<div style={styles.text}>Za vse pripombe, vprašanja, pritožbe, kršitve ali uporabniške izkušnje povezane s spletno stranjo lahko naslovite na nas preko e-naslova: info@glusic.si ali po pošti na naslov Odvetniška družba Glušič o.p., d.o.o. , od Javorji 6, PC Žeje pri Komendi, 1218 Komenda. </div>




						<div style={styles.subtitle}>8.	Reševanje sporov in uporaba prava</div>
						<div style={styles.text}>Za spore, ki bi izvirali iz uporabe te spletne strani ali e-novic, se bomo trudili, da se predmetni spori čim prej rešijo sporazumno. V kolikor to ne o mogoče je pristojno sodišče v Ljubljani. Uporablja se pravo Republike Slovenije. </div>


						<div style={styles.subtitle}>9.Veljavnost splošnih pogojev</div>
						<div style={styles.text}>Ti splošni pogoji se uporabljajo od dneva sprejema in objave dalje do njihovega preklica oziroma do morebitne spremembe.</div>
						<div style={styles.text}>Splošni pogoji veljajo od dne 9. 4. 2018.</div>
						<div style={styles.text}>Odvetniška družba Glušič o.p., d.o.o.</div>
					</div>
				</div>
			</div>

		);

	}
}




const styles = {
	wrapper:{
		position:"relative",
		backgroundColor:"#fff",
		width: "100%",
		paddingTop:70,
	},
	wrapperInner:{
		margin: "0 auto",
		width: "calc(100% - 60px)",
		maxWidth:1000,
		marginBottom:80,
	},
	text:{
		fontSize:15,
		fontWeight:500,
		marginBottom:10,
	},
	title:{
		fontSize:30,
		fontWeight:700,
		marginBottom:40,

	},
	subtitle:{
		fontSize:22,
		fontWeight:600,
		marginTop:30,
		marginBottom:20,
	},
	

};

export default Radium(PrivacyPage);