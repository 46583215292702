import React from 'react'
import Prispevek from "../components/Prispevek"
import Slider2 from "react-slick";
import Radium from 'radium';


class Slider extends React.Component {
	state = {
      open: false,
  };

componentWillMount(){
	this.setState({open: !this.state.open});
	setTimeout(() => {
    this.setState({open: !this.state.open});
  },50);
	setTimeout(() => {
    this.setState({open: !this.state.open});
  },300);
}

	render() {
		var settings = {
      speed: 500,
      arrows: true,
      autoplaySpeed:5000,
      autoplay:true,
      dots: false,
      infinite: true,
      nextArrow: <img alt="arrRight" src="assets/icons/arrRight.svg" />,
      prevArrow: <img alt="arrLeft" src="assets/icons/arrLeft.svg" />,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
      	{
       	breakpoint: 940,
          settings: {
            slidesToShow: 1,
      			slidesToScroll: 1,
          },
   			},
        {
       	breakpoint: 490,
          settings: {
          	slidesToShow: 1,
      			slidesToScroll: 1,
            arrows: false,   
          },
   			},
   		]
    };
    

		return (
			<div style={styles.wrapper}>
				<Slider2 {...settings}>
					{this.props.objava1.map((objava1, i) => {
						var category = "News";
				    if(this.props.objava1[i].kategorija === "Novica"){
				    	category = "News";
				    } else if(this.props.objava1[i].kategorija === "Prispevek"){
				    	category = "Publication";
				    }
						return(
							<div key={i} style={styles.background1}>
		        		<Prispevek text1={category} text2={this.props.objava1[i].title} text3="autor" text4={this.props.objava1[i].avtorObjave} text5="READ MORE" id={this.props.objava1[i].id} />
		        	</div>
		        )
		      })}
	        
	      </Slider2>
      </div>
		)
	}
}


const styles = {
	wrapper:{
		maxWidth:1400,
		margin:"0px auto",
		padding:"0px 50px",

		height:230,
		width:"calc(100% - 140px)",
		'@media (max-width: 560px)': {
			width:"calc(100% - 120px)",


		},
		'@media (max-width: 490px)': {
			width:"100%",
			padding:0,
		},
	},
	background1:{


		textAlign:"left",
		'@media (max-width: 940px)': {
			borderRight:"none",
		},
	},
	
};



export default Radium(Slider);