import React from 'react'

import Helmet from 'react-helmet'
import Radium from 'radium';

class NoMatch extends React.Component {
render() {
  return (
    <div className='NoMatch'>
      <div style={styles.content}>
        <div >
          <div style={styles.topPictureBoxTitle2}>404 - Stran ne obstaja</div>
          <div>
            Nismo našli strani katero ste iskali.<br />Nazaj na{' '}
            <a href={this.props.siteUrl}>
              {this.props.siteUrl.replace(/(^https?:\/\/)/, '').replace(/\/$/, '')}
            </a>
          </div>
        </div>
      </div>
      <Helmet>
        <title>404 – Stran ne obstaja</title>
        <body className='body--NoMatch' />
      </Helmet>
    </div>
  )
}}



export default Radium(NoMatch)



const styles = {
  //topPicture and box
  content:{
    position:"relative",
    backgroundColor:"#fff",
    width: "100%",
    padding:"80px 25px",
  },
  topPictureBoxTitle2: {
    fontSize:20,
    fontWeight:300,
    color:"#162A75",
    marginBottom:20,
    
  '@media (max-width: 500px)': {
    fontSize:17,
  },
},
}