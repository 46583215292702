import React from 'react';
import { NavLink } from 'react-router-dom';
import Radium from 'radium';


class Header extends React.Component {
	state = {
    open: false,
  };

	handleClick() {
    this.setState({open: !this.state.open});
    window.scrollTo(0,0);
  }


	render() {
		const navState = this.state.open ? styles.dropDownOpen : styles.dropDownClose;
	  	const hamburgerMenu1 = this.state.open ? styles.hamburgerMenuOpen1 : styles.hamburgerMenuClose1;
	  	const hamburgerMenu2 = this.state.open ? styles.hamburgerMenuOpen2 : styles.hamburgerMenuClose2;
	  	const hamburgerMenu3 = this.state.open ? styles.hamburgerMenuOpen3 : styles.hamburgerMenuClose3;


		return (
			<div style={styles.wrapper}>
      	<div style={styles.topWhiteBoxWrapper}>
	      	<div style={styles.topWhiteBox}>
			      <NavLink key="dsadsa" onClick={()=>window.scrollTo(0,0)} to="/home" style={styles.logoWrapper1}>
							<img style={styles.logo} src="../assets/icons/Glusic_logo.png" alt="Glušič logotip" />
						</NavLink>
						<NavLink onClick={()=>window.scrollTo(0,0)} to="/home" style={styles.link} activeStyle={styles.activelink}>Home
						</NavLink>
					  <NavLink onClick={()=>window.scrollTo(0,0)} to="/pisarna" style={styles.link} activeStyle={styles.activelink}>Practice Areas
					  </NavLink>
					  <NavLink onClick={()=>window.scrollTo(0,0)} to="/odvetniki" style={styles.link} activeStyle={styles.activelink}>Our Attorneys
					  </NavLink>
					  <NavLink onClick={()=>window.scrollTo(0,0)} to="/objave" style={styles.link} activeStyle={styles.activelink}>Publications
					  </NavLink>
					  <NavLink onClick={()=>window.scrollTo(0,0)} to="/kontakt" style={styles.link_last} activeStyle={styles.activelink}>Contact
					  </NavLink>
					  <div style={styles.languageWrapperDesktop}>
	      			<a href="https://www.glusic.si" style={styles.languageDesktop}>Slo</a>
	      			<a href="https://www.glusic.com" style={styles.languageDesktop}>Eng</a>
	      		</div>
			
      		</div>
	      	<div style={styles.topWhiteBox2}>
	      		<div style={styles.hamburgerMenu}  onClick={this.handleClick} >
	      			<div style={hamburgerMenu1}></div>
	      			<div style={hamburgerMenu2}></div>
	      			<div style={hamburgerMenu3}></div>
	      		</div>
	      		 <NavLink to="/Home" key="dsaddssa" style={styles.logoWrapper}>
							<img style={styles.logo} src="../assets/icons/Glusic_logo.png" alt="Glušič logotip" />
						</NavLink>
	      	</div>
	      	

      	</div>

      	<div style={navState}>
      		<div style={styles.DropDownBox}>
						<NavLink onClick={this.handleClick} to="/Home" style={styles.link2} activeStyle={styles.activelink}>Home
						</NavLink>
					  <NavLink onClick={this.handleClick} to="/Pisarna" style={styles.link2} activeStyle={styles.activelink}>Practice Areas
					  </NavLink>
					  <NavLink onClick={this.handleClick} to="/Odvetniki" style={styles.link2} activeStyle={styles.activelink}>Our Attorneys
					  </NavLink>
					  <NavLink onClick={this.handleClick} to="/Objave" style={styles.link2} activeStyle={styles.activelink}>Publications
					  </NavLink>
					  <NavLink onClick={this.handleClick} to="/Kontakt" style={styles.link2} activeStyle={styles.activelink}>Contact
					  </NavLink>
		
	      		<div style={styles.languageWrapper}>
	      			<a href="https://www.glusic.si" style={styles.language}>Slo</a>
	      			<a href="https://www.glusic.com" style={styles.language}>Ang</a>
	      		</div>

		      	
					</div>

      	</div>
      
      </div>
	
		);
	}
}

const styles = {
	notranji_del:{
		width: "calc(100% - 100px)",
		maxWidth:2400,
		display: "flex",
		margin: "20px auto",
		alignItems:"flex-end",
		flexFlow: "row nowrap",
		justifyContent: "space-between",
		paddingBottom: "20px",
		
		marginBottom: 0,
		'@media (max-width: 1064px)': {
	      	flexFlow: "row wrap",
	      	alignItems: "center",
	      	justifyContent: "space-evenly",
	      	paddingBottom: 0
	    }
	},
	linkWrapper:{
		display:"flex",
		listStyleType: "none",
		flexGrow:0,
		fontSize: 17,
		marginRight: 60,
		marginBottom: 20,
		'@media (max-width: 1200px)': {
	      	flexGrow: 0,
	      	marginRight: 30
	    },
	    '@media (max-width: 1064px)': {
	      	order: "2",
	      	margin: "20px auto",
	    }
	},

	linkLast:{
		paddingRight: "0px"
	},
	link:{
		textDecoration:"none",
		marginRight: "30px",
		color: "#152052",
	},

	link2: {
		display:"block",
		padding:"17px 0px",
		textAlign:"center",
		color:"#fff",
		borderBottom:"1px solid #fff",
		textDecoration:"none",
		cursor:"pointer",
	},
	link_last:{
		marginRight: 0,
		textDecoration:"none",
		color: "#152052",

	},
	languageWrapper:{
		height:54,
		display:"flex",
		justifyContent:"center",
		alignItems:"center",
	},
	language:{
		color:"#fff",
		marginLeft:6,
		textDecoration:"none",
		cursor:"pointer",
		marginRight:6,
		fontSize: 15,
	},
	languageWrapperDesktop:{
		marginLeft:40,
	},
	languageDesktop:{
		color:"#152052",
		marginLeft:10,
		textDecoration:"none",
		cursor:"pointer",
		fontSize: 15,
	},
	
	logo:{
		width:300,
		height:53.57,
		'@media (max-width: 1000px)': {
      	width:220,
      	height:39.28,
     },
     '@media (max-width: 360px)': {
      	width:200,
      	height:35.71,
     },
	},
	logoWrapper:{
			display:"flex",
			flexGrow:0,
			justifyContent:"flex-end",

	},
	logoWrapper1:{
		display:"flex",
		flexGrow:3,
		justifyContent:"flex-start",
		
	},
	activelink:{
		color: "#F89C2E"
	},

	dropdown:{
		position: "relative",

	},
	dropdown_content:{
		display: "none",
	    position: "absolute",
	    backgroundColor: "#fff",
	    minWidth: 250,
	    padding: "12px 16px",
	    zIndex: 1,
	},
















	image1: {
		marginRight:10,
		width:22,
	},
	image2: {
		width:22,
	},

hamburgerMenu: {
	flexGrow:1000000,
	cursor:"pointer",
},
hamburgerMenuOpen1: {
	height:2,
	width:26,
	background:"#000",
	margin:4,
	WebkitTransition: "0.2s linear",
	transition: "0.2s linear",
	WebkitTransitionDelay: "0.4s",
  	transitionDelay: "0.4s",
	transform:"translateY(6px) rotate(45deg)",
	
},
hamburgerMenuClose1: {
	height:2,
	width:22,
	background:"#000",
	margin:4,
	WebkitTransition: "0.4s",
	transition: "0.4s",
},


hamburgerMenuOpen2: {
	height:2,
	width:22,
	margin:4,
	background:"#000",
	WebkitTransition: "0.4s cubic-bezier(0,1,1,1)",
	transition: "0.4s cubic-bezier(0,1,1,1)",
	transform:"translateX(-100px)",
},
hamburgerMenuClose2: {
	height:2,
	width:22,
	margin:4,
	background:"#000",
	WebkitTransition: "0.3s cubic-bezier(0,0,0,1.2) 0.3s",
	transition: "0.3s cubic-bezier(0,0,0,1.2) 0.3s",
},


hamburgerMenuOpen3: {
	height:2,
	width:26,
	background:"#000",
	margin:4,
	WebkitTransition: "0.3s ease-out 0.2s",
	transition: "0.3s ease-out 0.2s",
	transform:"translateY(-6px) rotate(135deg)",
},
hamburgerMenuClose3: {
	height:2,
	width:22,
	background:"#000",
	margin:4,
	WebkitTransition: "0.4s",
	transition: "0.4s",
},





	wrapper:{
		borderBottom:"7px solid #142153",
	},
	topWhiteBoxWrapper: {
		margin:"0 auto",
		maxWidth:1100,
		width:"calc(100% - 80px)",
		padding:"12px 25px",
		background: "#fff",
		'@media (max-width: 1000px)': {
	    margin:"0 auto",
			maxWidth:1100,
			width:"calc(100% - 0px)",
			padding:"12px 25px",
			background: "#fff",
    },
	},
	topWhiteBox: {
		height:65,
		
		background: "#fff",

	  display: "flex",
		justifyContent: "space-between",
		alignItems:"center",
		'@media (max-width: 1000px)': {
      	display: 'none',
      },
	},
	topWhiteBox2: {
		height:40,
		
		background: "#fff",
	  display: "flex",
		justifyContent: "space-between",
		alignItems:"center",
		'@media (min-width: 1001px)': {
        display: 'none',
    },
	},
	topWhiteBox3: {
		height:65,
		
		background: "#4A4A4A",

	  display: "flex",
		justifyContent: "flex-end",
		alignItems:"center",
		'@media (min-width: 1001px)': {
          display: 'none',
      },
	},
	emailTelWrapperBobText:{
		paddingLeft:10,
	},
	emailTelWrapper:{
		display:"flex",
		margin: "0px 10px",
		flexGrow:1,
		alignItems:"center",
	},
	phone3: {
		width:38,
	},
	emailTelWrapperDropdownNav: {
		display:"flex",
		margin: "25px 10px",
		// marginBottom:10,
		justifyContent:"center",
		alignItems:"center",
	},
	emailTelWrapperDropdownNav2: {
		display:"flex",
		cursor:"pointer",
		justifyContent:"center",
		alignItems:"center",
	},
	emailTelWrapperText1: {
		fontSize: 11,
		color: "#4A4A4A",
		paddingBottom:5,
	},
	emailTelWrapperText2: {
		fontSize: 11,
		color: "#8B8B8B",
		fontWeight:400,
		whiteSpace:"nowrap",
	},
	image: {
		marginRight:15,
	},
	
	
	firstLink: {
		marginLeft:0,
	},
	firstLinkDropDown: {
		marginTop:35,
	},
	active: {
		paddingTop:1,
		borderBottom: "1px #CC0000 solid",
	},
	active2: {
		paddingTop:7,
	},

button: {
	background:"#CC0000",
	color:"#fff",
	fontWeight:300,
	fontSize:13,
	padding:"9px 25px",
	borderRadius:20,
	width:130,
	textAlign:"center",
},
button2: {
	background:"#CC0000",
	color:"#fff",
	fontWeight:300,
	fontSize:13,
	padding:"9px 25px",
	borderRadius:20,
	width:130,
	textAlign:"center",
	'@media (max-width: 403px)': {
      padding:"8px 15px",
      width:100,
   },
},
buttonWrapper: {
	display:"flex",
	margin:15,
	justifyContent:"center",
},

lineOpen:{
	borderBottom:"2px solid #cc0000",
	ransition:"0.3s",
	background:"#fff",
	'@media (min-width: 1001px)': {
     borderBottom:"0px solid #cc0000",
  },
},
lineClose:{
	borderBottom:"0px solid #cc0000",
	WebkitTransition:"0.3s",
	transition:"0.3s",
	background:"#fff",
},
dropDownOpen: {
	height:315,

	position:"relative",
	background:"#142153",
	WebkitTransition:"0.3s",
	transition:"0.3s",
	'@media (min-width: 1001px)': {
      display: 'none',
   },
},
dropDownClose: {
height:0,

position:"relative",
margin:0,
background:"#142153",
padding:0,
WebkitTransition:"0.3s",
transition:"0.3s",
},
DropDownBox: {
	background:"#142153",
	display:"block",

},
emailTelWrapperWrapper: {
	display:"flex",
	paddingTop:20,
	justifyContent:"center",
},
};

export default Radium(Header);