import React from 'react';
import '../globalStyles.css'
import Content from '../components/Content'
import './PublicationPage.css'

const PublicationPage = ({fields, prevPostURL, nextPostURL, history}) => {
	const { title, body, avtorObjave, krajDogotka, datumDogotka, date, kategorija, keyWords = [], minus = [], plus = [] } = fields

	function handlePrew(){
		window.scrollTo(0,0);
		history.push(prevPostURL)
	}
	function handleNext(){
		window.scrollTo(0,0);
		history.push(nextPostURL)
	}

	const dateString = typeof date === "object" ? `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}` : date
	const eventDateString = typeof datumDogotka === "object" ? `${datumDogotka.getFullYear()}-${datumDogotka.getMonth()}-${datumDogotka.getDate()}` : datumDogotka


	return(
		<div style={styles.wrapperOuter}>
			<div key="123" style={styles.wrapper}>
				<div style={styles.wrapperInner}>
					<div>
						<div style={styles.clearfix}>
							<div style={styles.boxWrapper}>
								{kategorija !== "Ne spreglejte" ?
									<div style={styles.box1}>

										{kategorija !== "Dogodek" ?
											<div key="11">
												<div key="12e33" style={styles.author1}>
													autor
												</div>
												<div key="1234ds3" style={styles.author2}>
													{avtorObjave}
												</div>

												<div key="12pdc3" style={styles.date}>
													{dateString}
												</div>
											</div>
											:
											<div key="32">
												<div key="123daa" style={styles.kdaj}>
													When?
												</div>
												<div key="1" style={styles.dogodekDateLocation}>
													{eventDateString}
												</div>
												<div key="12133" style={styles.kje}>
													Where?
												</div>
												<div key="2" style={styles.dogodekDateLocation}>
													{krajDogotka}
												</div>
											</div>

										}
										<div style={styles.shareButton}>
											<a key="1c" href={"https://twitter.com/intent/tweet?text="+window.location.href} data-size="large" style={styles.twitter}>
												<img alt="linkedln" style={styles.twitterLogo} src="../assets/icons/linkedln.png" />
											</a>
											<a key="pspx" href={"https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href} style={styles.inkedln}>
												<img alt="twitter" style={styles.inkedlnLogo} src="../assets/icons/twitter.png" />
											</a>


										</div>
									</div>
									:
									null
								}

								{keyWords.length>0 ?
									<div key="pcpd" style={styles.box2}>
										<div key="1zc3" style={styles.keyWordTitle}>
											Key words
										</div>

										{keyWords.map((objava, i) => <div style={styles.keyWord} key={i}>{keyWords[i].key_word}</div>)}

									</div>
									:
									null
								}

								{minus.length>0 || plus.length>0 ?
									<div key="1421" style={styles.box4}>
										{plus.length>0 ?
											<div key="pxd" style={styles.box4Flex}>
												<img alt="plus" src="../assets/icons/plus.png" style={{width:30,height:30,marginRight:10,}} />
												<div key="pcx3">
													{plus.map((objava, i) => <div style={styles.word} key={i}>{plus[i].word}</div>)}
												</div>
											</div>
											:
											null
										}
										{minus.length>0 ?
											<div key="1111" style={styles.box4Flex}>
												<img alt="minus" src="../assets/icons/minus.png" style={{width:30,height:3,marginRight:10,marginTop:14,}} />
												<div key="14333">
													{minus.map((objava, i) => <div style={styles.word} key={i}>{minus[i].word}</div>)}
												</div>
											</div>
											:
											null
										}


									</div>
									:
									null
								}
							</div>



							<div key="ppdds" style={styles.title}>
								{title}
							</div>

							<Content source={body} />

							{kategorija === "Ne spreglejte" ?
								<div key="vbzas" style={styles.shareButton}>
									<a key="433" href={"https://twitter.com/intent/tweet?text="+window.location.href} data-size="large" style={styles.twitter}>
										<img alt="linkedln" style={styles.twitterLogo} src="../assets/icons/linkedln.png" />
									</a>
									<a key="2bbbvc3" href={"https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href} style={styles.inkedln}>
										<img alt="twitter" style={styles.inkedlnLogo} src="../assets/icons/twitter.png" />
									</a>
								</div>
								:
								null
							}
							{keyWords.length>0 ?
								<div key="bmsl" style={styles.box3}>
									<div key="zxcvs3" style={styles.keyWordTitle}>
										Ključne besede
									</div>

									{keyWords.map((objava, i) => <div style={styles.keyWord} key={i}>{keyWords[i].key_word}</div>)}

								</div>
								:
								null
							}

						</div>
					</div>
				</div>
			</div>

			<div key="1433" style={stylesCon.wrapper}>
				<div key="bgsaz" style={stylesCon.prew} onClick={handlePrew}>
					<img alt="arrLeft" style={stylesCon.prewImg} src="../assets/icons/arrLeft.png" />
					<div key="1fasa" style={stylesCon.text}>Previous post</div>
				</div>
				<div key="1223323" style={stylesCon.next} onClick={handleNext}>

					<div key="5467123" style={stylesCon.text}>Next post</div>
					<img alt="arrRight" style={stylesCon.nextImg} src="../assets/icons/arrRight.png" />
				</div>
			</div>

		</div>
	)
}

const stylesCon = {
	wrapper:{
		display:"flex",
		background:"#F5A623",
	},
	prew:{
		cursor:"pointer",
		padding:"20px 40px",
		width:"calc(50% - 0px)",
		background:"#F6BD5E",
		display:"flex",
		alignItems:"center",
		justifyContent:"space-between",
		transition:"0.2s",
		"@media (max-width: 670px)": {
      width:"calc(50% - 0px)",
      padding:"20px 30px",
    },
    "@media (max-width: 470px)": {
       width:"calc(50% - 0px)",
      padding:"20px 20px",
    },
    "@media (max-width: 430px)": {
      paddingLeft:10,
    },
    ":hover":{
    	background:"#EF9500",
    },
	},
	next:{
		cursor:"pointer",
		padding:"20px 40px",
		width:"calc(50% - 0px)",
		justifyContent:"space-between",
		background:"#F5A623",
		display:"flex",
		alignItems:"center",
		transition:"0.2s",
		"@media (max-width: 670px)": {
      width:"calc(50% - 0px)",
      padding:"20px 30px",
    },
    "@media (max-width: 470px)": {
       width:"calc(50% - 0px)",
      padding:"20px 20px",
    },
    "@media (max-width: 430px)": {
      paddingRight:10,
    },
    ":hover":{
    	background:"#EF9500",
    },
	},
	nextImg:{
		width:30,
		"@media (max-width: 570px)": {
      width:20,
    },
	},
	prewImg:{
		width:30,
		"@media (max-width: 570px)": {
       width:20,
    },
	},
	text:{
		textAlign:"center",
		flexGrow:10,
		letterSpacing:1,
		fontSize:25,
		color:"#fff",
		fontWeight:300,
		"@media (max-width: 670px)": {
      flexGrow:0,
    },
    "@media (max-width: 570px)": {
      fontSize:20,
    },
    "@media (max-width: 430px)": {
      fontSize:17,
    },
    "@media (max-width: 370px)": {
      width:100,
    },
	},

};

const styles = {
	

	wrapperOuter:{
		background:"#fff",
		// width:"100%",
	},
	wrapper:{
		background:"#fff",
		margin:"0px auto",
		maxWidth: 1200,
		width:"calc(100% - 100px)",
		"@media (max-width: 570px)": {
      width:"calc(100% - 60px)",
    },
    "@media (max-width: 450px)": {
      width:"calc(100% - 40px)",
    },
    "@media (max-width: 370px)": {
      width:"calc(100% - 30px)",
    },
	},
	wrapperInner:{
		paddingTop:50,
		paddingBottom:50,
	},
	clearfix: {
    overflow: "auto",
	},

	boxWrapper: {
    float: "right",
    marginLeft:30,
    marginBottom:30,
    width:"300px",
    "@media (max-width: 750px)": {
    	float: "right",
		  marginLeft:30,
		  marginBottom:30,
      width:"100%",
    },
	},
	box1:{
		width:"100%",
		zIndex:10,
		position:"relative",
		border:"solid #F5A623 2px",
		borderRadius:5,
		padding:40,
	},
	box2:{
		zIndex:10,
		marginTop:20,
		position:"relative",
		border:"solid #F5A623 2px",
		borderRadius:5,
		padding:40,
		display:"block",
		"@media (max-width: 750px)": {
      display:"none",

    },
	},
	box3:{
		zIndex:10,
		position:"relative",
		marginTop:20,
		border:"solid #F5A623 2px",
		borderRadius:5,
		padding:40,
		display:"none",
		"@media (max-width: 750px)": {
			zIndex:10,
			position:"relative",
			marginTop:20,
			border:"solid #F5A623 2px",
			borderRadius:5,
			padding:40,
      display:"block",
    },
	},
	box4:{
		zIndex:10,
		position:"relative",
		marginTop:20,
		border:"solid #243353 2px",
		borderRadius:5,
		paddingBottom:40,
		paddingRight:40,
		paddingTop:10,
		paddingLeft:30,
	},

	box4Flex:{
		display:"flex",
		marginTop:30,
	},
	author1:{
		marginTop:0,
		color:"#243353",
		fontSize:15,
	},
	author2:{
		color:"#F5A623",
		fontSize:20,
	},

	dogodekDateLocation:{
		color:"#243353",
		fontSize:30,
		fontWeight:300,
	},
	kdaj:{
		color:"#F5A623",
		fontSize:20,
	},
	kje:{
		marginTop:20,
		color:"#F5A623",
		fontSize:20,
	},

	date:{
		marginTop:20,
		color:"#243353",
		fontSize:25,
		fontWeight:300,
	},
	shareButton:{
		display:"flex",
		marginTop:50,
	},
	twitter:{
		display:"flex",
		justifyContent:"center",
		alignItems:"center",
		background:"#F5A623",
		borderRadius:"50%",
		width:35,
		height:35,
	},
	twitterLogo:{
		width:20,
	},
	inkedln:{
		display:"flex",
		justifyContent:"center",
		alignItems:"center",
		background:"#F5A623",
		borderRadius:"50%",
		marginLeft:10,
		width:35,
		height:35,
	},
	inkedlnLogo:{
		width:16,
	},

	keyWordTitle:{
		fontSize:20,
		color:"#243353",
		fontWeight:300,
		marginBottom:15,
	},
	keyWord:{
		color:"#777777",
		fontSize:15,
		marginBottom:4,
		fontWeight:400,
	},
	word:{
		color:"#243353",
		fontSize:15,
		marginTop:5,
		marginBottom:5,
		fontWeight:400,
	},


	title:{
		color:"#243353",
		fontWeight:300,
		fontSize:40,
		marginBottom:30,
		width:"calc(100% - 350px)",
		"@media (max-width: 750px)": {
      	width:"100%",
    },
		"@media (max-width: 570px)": {
      	fontSize:30,
    },
    "@media (max-width: 450px)": {
      	fontSize:25,
    },
	},
	subtitle:{
		width:"calc(100% - 350px)",
		color:"#243353",
		fontWeight:400,
		fontSize:32,
		marginBottom:20,
		"@media (max-width: 750px)": {
      	width:"100%",
    },
		"@media (max-width: 570px)": {
      fontSize:25,
    },
    "@media (max-width: 450px)": {
      	fontSize:22,
    },
	},
	text:{
		width:"calc(100% - 350px)",
		letterSpacing:0.7,
		lineHeight:1.5,
		color:"#243353",
		fontWeight:300,
		fontSize:19,
		marginBottom:30,
		"@media (max-width: 750px)": {
      	width:"100%",
    },
		"@media (max-width: 570px)": {
      fontSize:15,
    },
	}
	
};

export default PublicationPage;