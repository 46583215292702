import React from 'react';
import Radium from 'radium';

class MyForm extends React.Component {
	state = {
    email:"",
    location:"home",
    nextScreen: true,
    emailValue: '',
    fNameValue: '',
    lNameValue: '',
  };

	handleClick(){
  	this.setState({ nextScreen: false});

  }

	render() {
		return (
			<div style={styles.content}>
				<div style={styles.prijava}>
					<div style={styles.prijava_form}>
						Subscribe to e-news
						{this.state.nextScreen ? 
							<form action="https://glusic.us18.list-manage.com/subscribe/post?u=f61d8f138bd786e35510183d5&amp;id=be07e7bd29" method="POST" noValidate>
                <input type="hidden" name="u" value="f61d8f138bd786e35510183d5"/>
                <input type="hidden" name="id" value="10e6ad6f3e"/>
                <div style={styles.prijava_list}>
		                <label style={styles.contacts_form_inputWraper_design} htmlFor='MERGE0'>
		                	<input 
		                		style={styles.contacts_form_input}
		                    type="email" 
	
		                    id="MERGE0"
		                    value={this.state.emailValue} 
		                    onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
		                    autoCapitalize="off" 
		                    autoCorrect="off"
		                    placeholder="e-mail"
		                   /> 
		                </label>
	                <input type="submit" value="SUBSCRIBE" name="subscribe" id="mc-embedded-subscribe" style={styles.prijava_gumb}/>
	              </div>

                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                    <label htmlFor="b_name">Name: </label>
                    <input type="text" name="b_name" tabIndex="-1" value="" placeholder="Freddie" id="b_name"/>

                    <label htmlFor="b_email">Email: </label>
                    <input type="email" name="b_email" tabIndex="-1" value="" placeholder="youremail@gmail.com" id="b_email"/>

                    <label htmlFor="b_comment">Comment: </label>
                    <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
                </div>
              </form>
							:
								<div style={styles.massagePrijava}>
									Thank you
								</div>
							}
					</div>
				</div>
			</div>
		);
	}
}



const styles = {
	//topPicture and box
	content:{
		position:"relative",
		backgroundColor:"#fff",
		width: "100%",
	},
	
	prijava:{
		width: "100%",
		backgroundColor: "grey",
		height: "30vh",
		marginBottom: 8,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: "linear-gradient(to bottom right,rgba(36, 51, 83, 0.7),rgba(36, 51, 83, 0.7)), url(../assets/pictures/iStock_000027753701_Medium-e1455794128429.png)",
		backgroundSize: "cover",
		'@media (max-width: 1200px)': {
					height: "25vh"
			},
		'@media (max-width: 320px)': {
				height: "30vh"
		},
	},
	prijava_form:{
		width: "100%",
		textAlign:"center",
		position: "relative",
		top: "-10%",
		fontSize: 41,
		fontWeight: "300",
		color: "#FFF",
		diplay: "block",
		'@media (max-width: 1150px)': {
			fontSize: 35,
			},
			'@media (max-width: 820px)': {
			fontSize: 30,
			},
			'@media (max-width: 700px)': {
			fontSize: 25,
			top: 0
			},
			'@media (max-width: 320px)': {
			fontSize: 25,
			top: "-5%"
			},
	},
	prijava_list:{
		diplay: "flex",
		justifyContent: "center",
		position: "relative",

		top: 30,
		'@media (max-width: 1000px)': {
			top: 20
			},
			'@media (max-width: 700px)': {
			top: 10
			},
	},
	contacts_form_inputWraper_design:{
		borderRadius: 4,
		width: "28%",
		padding: "2px 0px 10px 15px",
		display: "inline-block",
		position: "relative",
		left: -13,

		backgroundColor: "#FFF",
		'@media (max-width: 1150px)': {
			padding: "9px 0px 9px 15px",
			},
			'@media (max-width: 1000px)': {
			padding: "0px 0px 9px 15px"
			},
			'@media (max-width: 820px)': {
			width: "32%"
			},
			'@media (max-width: 700px)': {
			display: "block",
			marginTop:"0px",
			marginLeft:"auto",
			marginRight:"auto",
			marginBottom: 0,
			left: 0,
			width: "40%"
			},
			'@media (max-width: 600px)': {
			width: "50%"
			},
	},
	contacts_form_input:{
		fontSize: 19,
		fontWeight: 300,
		borderTop: "none",
		borderBottom: "none",
		borderRight: "none",
		borderRadius:0,
		borderLeft: "2px solid #F5A623",
		padding: "10px 0px 10px 15px",
		width: "90%",
		outline: "none",
		height:"40px",
		'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px",
			fontSize: 17,
			},	
			'@media (max-width: 820px)': {
			padding: "3px 0px 3px 10px",
			fontSize: 15,
			},
	},

	prijava_gumb:{
		marginLeft: "10px",
		backgroundColor: "#F89C2E",
		border: "none",
		padding: "10px 75px",
		color: "#fff",
		borderRadius: 4,
		fontSize: 15.84,
		letterSpacing: 2,
		fontWeight: 300,
		transition: "all .2s",
		cursor: "pointer",
		'@media (max-width: 1000px)': {
			padding: "10px 65px",
			fontSize: 15
			},
			'@media (max-width: 900px)': {
			padding: "5px 35px",
			fontSize: 13
			},
			'@media (max-width: 700px)': {
			margin: "0px auto"
			},
		":hover":{

			boxShadow: "0px 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
			transform: "translateY(-2px) scaleX(1.02) scaleY(1.02)",
		},

		":active":{
			outline: "none",
			transform: "translateY(-1px)",

		},
		":focus":{
			outline: "none",
			

		},
	},

	massagePrijava:{
		color:"#fff",
		fontWeight:300,
		fontSize:30,
		marginTop:25,
		marginBottom:-10,
	},


};

 export default Radium(MyForm);
