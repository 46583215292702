import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

import './Content.css'

const encodeMarkdownURIs = (source = '') => {
  const markdownLinkRegex = /\[(?:\[[^\]]*\]|[^[\]])*\]\([ \t]*<?((?:\([^)]*\)|[^()])*?)>?[ \t]*(['"].*?\6[ \t]*)?\)/g
  return source.replace(markdownLinkRegex, (match, linkURI) => {
    if (!linkURI) return match
    const replaced = match.replace(linkURI, encodeURI(linkURI))
    return replaced
  })
}


const Content = ({ source, src, className = '' }) => (
  <ReactMarkdown
    className={`Content ${className}`}
    children={encodeMarkdownURIs(source || src)}
    components={{
      img: (props) => <img className='Content--Image' {...props} alt=""/>
    }}
  />
)

Content.propTypes = {
  source: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string
}

export default Content
