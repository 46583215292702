import React, { Fragment, useEffect, useState} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// import Meta from './components/Meta'
import HomePage from './views/HomePage'

import Pisarna from './views/Pisarna.js'
import Kontakti from './views/Kontakti.js'
import Odvetniki from './views/Odvetniki.js'
import Objave from './views/Objave.js'
import PrivacyPage from './views/PrivacyPage.js'
import PublicationPage from './views/PublicationPage.js'
import ReactGA from 'react-ga';

import Meta from './components/Meta'

import NoMatch from './views/NoMatch'
import Footer from './components/Footer'
import Nav from './components/Header'
import CookiesPopup from './components/cookiesPopup'


import data from './data.json'
import { slugify } from './util/url'

ReactGA.initialize('UA-120092449-1',{
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});

// const RouteWithMeta = (props) => {
//   const { element: Element, ...rest } = props
//   ReactGA.set({ page: window.location.pathname + window.location.search });
//   ReactGA.pageview(window.location.pathname + window.location.search);
//   return (
//     <Route
//       {...rest}
//       element={
//         <Fragment>
//           <Meta {...rest} />
//           <Element {...rest} />
//         </Fragment>
//       }
//     />
//   )
// }

const RouteWithMeta = ({ component: Component, ...props }) => {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);


  return(
    <Route
      {...props}
      render={routeProps => (
        <Fragment>
          <Meta {...props} />
          <Component {...routeProps} {...props} />
        </Fragment>
      )}
    />
  )
}

const App = () => {
  const [objave, setObjave] = useState([...data.objave])


  useEffect(() => {
    const arr = [];
    for(let i = 0; i<data.objave.length; i++){
      arr.push(data.objave[i]);
      arr[i].id = i;
    }
    setObjave([...arr])
  }, [])

  return(
    <Router>
      <div className='React-Wrap'>
        <Nav />
        <main>
          <Switch >
            <RouteWithMeta
              path='/'
              exact
              component={HomePage}
              fields={objave}
            />
            <RouteWithMeta
              path='/home'
              exact
              component={HomePage}
              fields={objave}
            />
            <RouteWithMeta
              path='/pisarna'
              exact
              component={Pisarna}
              fields={objave}
            />
            <RouteWithMeta
              path='/kontakt/'
              exact
              component={Kontakti}
              fields={objave}
            />
            <RouteWithMeta
              path='/odvetniki/'
              exact
              component={Odvetniki}
              fields={objave}
            />
            <RouteWithMeta
              path='/objave/'
              exact
              component={Objave}
              fields={objave}
            />
            <RouteWithMeta
              path='/privacy/'
              exact
              component={PrivacyPage}
              fields={objave}
            />
            <RouteWithMeta
              path='/404'
              exact
              component={HomePage}
              fields={objave}
            />

            {objave.map((post, index) => {
              const path = slugify(`/view/${index}`)
              var nextPost = (index + 1)
              var prevPost = (index - 1)
              if(index === 0){
                prevPost = data.objave.length - 1
              }
              if(data.objave.length-1 === index){
                nextPost = 0
              }
              return (
                <RouteWithMeta
                  key={path}
                  path={path}
                  exact
                  component={PublicationPage}
                  fields={post}
                  nextPostURL={slugify(`/view/${nextPost}`)}
                  prevPostURL={slugify(`/view/${prevPost}`)}
                />
              )
            })}


            <Route render={() => <NoMatch siteUrl={data.settings[0].siteUrl} />} />
          </Switch>
        </main>
        <CookiesPopup />
        <Footer />
      </div>
    </Router>
  )
}


export default App
