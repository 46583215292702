import React from 'react';
import { Link } from 'react-router-dom';
import Radium from 'radium';


class Footer extends React.Component {
	render(){
		return (
			<footer style={styles.footer}>
				<div style={styles.footer_content}>
					<div style={styles.footer_content_logo}>
						<img alt="" src ="../assets/icons/bel_logo.svg" />
					</div>
					<div style={styles.footer_content_storitve}>
						<Link onClick={()=>window.scrollTo(0,0)} to="/" key="123ds3" style={styles.link}>Home</Link>
						<Link onClick={()=>window.scrollTo(0,0)} key="1as23" to="/Pisarna" style={styles.link}>Practice Areas</Link>
						<Link onClick={()=>window.scrollTo(0,0)} key="12dsad3" to="/Odvetniki" style={styles.link}>Our Attorneys</Link>
						<Link onClick={()=>window.scrollTo(0,0)} key="123gh" to="/Objave" style={styles.link}>Publications</Link>
						<Link onClick={()=>window.scrollTo(0,0)} key="ewq123" to="/Kontakt" style={styles.linkLast}>Contact</Link>
					</div>
					<div style={styles.footer_content_map}>
						<div style={styles.footer_content_map_text}>Law firm Glušič o.p., d.o.o.</div>
						<div style={styles.footer_content_noMargin}>Pipanova pot 30,</div>
						<div style={styles.footer_content_noMargin}>1000 Ljubljana</div>
					</div>
					<div style={styles.footer_content_phones}>
						<div style={[styles.footer_content_phones_inline, styles.footer_content_phones_inline_words]}>T</div>
						<div style={[styles.footer_content_phones_inline, styles.footer_content_phones_inline_numbers]}>+386 1 234 5347</div>
						<div style={[styles.footer_content_phones_inline, styles.footer_content_phones_inline_words]}>M</div> 
						<div style={[styles.footer_content_phones_inline, styles.footer_content_phones_inline_numbers]}>+386 31 335 435</div>
					</div>
					<div style={{width:"100%"}}>
						<Link style={styles.privacy} to="/Privacy">Terms of Use/Privacy Policy</Link>
						<a href="../assets/Politika_piskotkov.pdf" style={styles.privacy}>Cookies</a>
					</div>
					<div style={styles.footer_content_copyright}>
						<div style={[styles.footer_content_copyright_all, styles.footer_content_copyright_1]}>
							&copy; 2020 Law firm Glušič o.p., d.o.o.
						</div>
						<div style={[styles.footer_content_copyright_all, styles.footer_content_copyright_2]}>
							All rights reserved
						</div>
					</div>
					<a rel="noopener noreferrer" target="_blank" href="http://www.odv-zb.si/predstavitev/predstavitev" style={{color:"#fff",textDecoration:"none",marginTop:15,fontWeight:300}}>www.odv-zb.si</a>
				</div>
			</footer>
		);
	}
}


const styles = {
	footer:{
		
		//width: "calc(100% - 100px)",
		//height: "39vh",
		background:  "#142153",
		margin: " 0px auto",
	},
	footer_content:{
		//height: "100%",
		width: "calc(100% - 100px)",
		margin: "0px auto",
		maxWidth: 1400,
		display: "flex",
		justifyContent: "space-between",
		padding: "30px 0px",
		flexFlow: "row wrap",
	    '@media (max-width: 1190px)': {
	      	width: "85%"
	    },
	    '@media (max-width: 975px)': {
	      	width: "90%"
	    }
	},
	/*footer_content_row_1:{
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "3% 0",
	},*/
	footer_content_storitve:{
		display: "flex",

		alignItems: "center",
		'@media (max-width: 850px)':{
			justifyContent: "space-between",
			display:"none",
			// order: "2",
			// width: "100%",
			// margin: "40 auto 20",
			// width: "70%"
		},
	},
	footer_content_map:{
		display: "block",
		width: "100%",
		textAlign: "left",
		fontSize: 15,
		fontWeight: 200,
		color: "#FFF",
		margin: "40px 0px 50px",
		'@media (max-width: 850px)':{
			margin: "20px 0px 30px"
		},
	},
	footer_content_map_text:{
		marginBottom: 20,

	},
	linkLast:{
		margin: 0,
		textDecoration:"none",
		color: "#FFF",
		fontSize: 15,
		fontWeight: 300,
	},
	link:{
		textDecoration:"none",
		marginRight: "40px",
		color: "#FFF",
		fontSize: 15,
		fontWeight: 300,
	},
	privacy:{
		fontWeight:300,
		marginLeft:20,
		textDecoration:"none",
		marginBottom:20,
		color: "#FFF",

		
	},
	footer_content_row_2_noMargin:{
		margin: 0
	},
	footer_content_phones:{
		//display: "flex"
		width: "40%",
		marginBottom:20,
		display: "flex",
		'@media (max-width: 850px)':{
			justifyContent: "flex-start",
			width: "100%",
		},

	},
	footer_content_phones_inline:{
		width: "30%",
		display: "inline-block",

	    '@media (max-width: 850px)':{
			width: "auto",
		},
	},
	footer_content_phones_inline_words:{
		fontSize: 15,
		fontWeight: 700,
		display: "inline",
		color: "#F89C2E",
		marginRight: 13,
		width: "auto"
	},
	footer_content_phones_inline_numbers:{
		fontSize: 15,
		fontWeight: 200,
		color: "#FFF",
		marginRight: 20,
		'@media (max-width: 850px)':{
			width: "15%",
		},
		'@media (max-width: 525px)':{
			width: "25%",
		},
		'@media (max-width: 315px)':{
			width: "30%",
		},
	},
	footer_content_copyright:{
		fontSize: 15,
		fontWeight: 200,
		color: "#FFF",
		width:"100%",
		marginTop:30,
		textAlign: "right",
		display: "flex",
    '@media (max-width: 850px)':{
			order: "3",
			marginTop:30,
			marginBottom:"0px",
			marginLeft:"auto",
			marginRight:"auto",
			justifyContent: "space-between",
		},

		'@media (max-width: 485px)':{
			flexDirection:"column",
			justifyContent: "flex-start",
			alignItems:"flex-start",
		},
		'@media (max-width: 333px)':{
			fontSize: 15
		},
		'@media (max-width: 295px)':{
			fontSize: 13
		},
	},
	footer_content_copyright_all:{
		display: "inline-block"
	},
	footer_content_copyright_1:{
		paddingRight: 50,
		'@media (max-width: 485px)':{
			marginBottom: 10,
			paddingRight: 0,
		},
	}


};


export default Radium(Footer);

