import React from 'react';
import Radium from 'radium';
import Content from '../components/Content'
import './VrstaObjave.css'

var Link = require('react-router-dom').Link;
Link = Radium(Link);

var img = "";

class VrstaObjave extends React.Component {
	state = {

	};

	render(){
		
		const { id, title, content, avtorObjave, krajDogotka, datumDogotka, kategorija } = this.props;

		if(kategorija === "Prispevek"){
			img = "../assets/icons/prispevekColor.png";
		} else if(kategorija === "Novica"){
			img = "../assets/icons/novicaColor.png";
		} else if(kategorija === "Ne spreglejte"){
			img = "../assets/icons/spreglejteColor.png";
		} else if(kategorija === "Dogodek"){	
			img = "../assets/icons/dogodekColor.png";
		} 

		var text;
		if(content !== undefined){
			text = `${content.substring(0, 223)}${content.length>222 ? "..." : null}`;
		}

	
		return(
			<div style={styles.wrapper}>
				<div style={styles.imgWrapper}>
					<img alt="" style={styles.img} src = {img}/>
				</div>
				<div style={styles.border}>
				</div>
				<div className="objava" style={styles.objava}>
					<div style={styles.naslov}>{title}</div>
					{kategorija === "Dogodek" ? 
							<div>
								<div style={styles.avtor}>When?</div>
								<div style={styles.datumDogodka}>{datumDogotka}</div>
							</div>
						: 
							<div style={styles.avtor}>Author</div>
					}
					{kategorija === "Dogodek" ? 
							<div style={styles.avtor}>Where?</div>
						: 
							<div style={styles.imePriimek}>{avtorObjave}</div>
					}
					{kategorija === "Dogodek" ? 
							<div style={styles.datumDogodka}>{krajDogotka}</div>
						: 
							<Content source={text} />
					}
					
					<Link onClick={()=>window.scrollTo(0,0)} to={`/view/${id}`} href="" style={styles.text5}><div>READ MORE</div> <img alt="arrowRight" style={styles.img2} src="../assets/icons/arrowRight.png" /></Link>
				</div>
			</div>

		);

	}
}




const styles = {
	wrapper:{

		display: "flex",
		flexFlow: "row nowrap",
		border: "1px solid #243353",
		borderRadius: 4,

		justfyContent: "space-evenly",
		padding: "35px 66px 30px 35px",
		marginTop:"0px",
		marginLeft:"auto",
		marginRight:"auto",
		marginBottom: 20,
		'@media (max-width: 550px)':{
			padding: "35px 26px 30px 26px",
			width:"calc(100% - 0px)",
		},
		'@media (max-width: 450px)':{
			flexFlow: "column nowrap",
			padding: "25px 26px 29px 26px",
			width:"calc(100% - 0px)",
		},
	},
	imgWrapper:{
		paddingRight: 30,
		'@media (max-width: 500px)':{
			paddingRight: 20
		},
		'@media (max-width: 450px)':{
			paddingBottom:10,
			textAlign: "center",
		},
	},
	img:{
		height:45,
	},
	border:{
		'@media (max-width: 450px)':{
			borderBottom: "1px solid #F5A623",
			marginBottom: 15,
			marginTop:10,
		},
	},
	naslov:{
		fontSize: 30,
		color: "#243353",
		paddingBottom: "25px",
		'@media (max-width: 570px)':{
			fontSize: 25
		},
		'@media (max-width: 450px)':{
			paddingBottom: 10
		},
		'@media (max-width: 370px)':{
			fontSize: 22
		},
	},
	avtor:{
		fontSize: 19,
		color: "#243353",
		fontStyle: "italic",
		fontWeight: 300,
		'@media (max-width: 370px)':{
			fontSize: 19,
		},
	},
	imePriimek:{
		fontSize: 20,
		color: "#F5A623",
		fontWeight: 600,
		paddingBottom: "20px",
		'@media (max-width: 370px)':{
			fontSize: 17
		},
	},
	datumDogodka:{
		fontSize: 19,
		color: "#F5A623",
		fontWeight: 600,
		paddingBottom: "20px",
		'@media (max-width: 370px)':{
			fontSize: 17
		},
	},
	besedilo:{
		fontSize: 19,
		color: "#243353",
		height:45,
		overflow:"hidden",
		fontWeight: 300,
		marginBottom: 20,
		'@media (max-width: 450px)':{
			fontSize: 17,
			height:40,
		},
		'@media (max-width: 370px)':{
			fontSize: 15,
			height:37,
		},
	},
	link:{
		color:"#F5A623",
		fontSize:17,
		fontWeight:500,
		display: "inline-block",
		padding: 2,
		textDecoration: "none",
		backgroundImage: "linear-gradient(120deg, transparent 0%, transparent 50%, #F5A623 50%)",
		backgroundSize: "220%",
		transition: "all .1s",

		":hover":{
			fontWeight:700,
			
			
		}

	},

	text5:{
		textDecoration:"none",
		color:"#F5A623",
		fontSize:20,
		display:"flex",
		fontWeight:400,
		height:30,
		transition:".2s",
		width:135,
		justifyContent:"space-between",
		alignItems:"center",
		":hover":{
			width:145,
		}
	},
	img2:{
		height:18,
	}

};

export default Radium(VrstaObjave);