import React from 'react'
import Helmet from 'react-helmet'



const Meta = props => {
  var { title, postFeaturedImage, opis } = props.fields && props.fields;
  const { path } = props;
  if(document.getElementById('meta1')){
    document.getElementById('meta1').remove();
    document.getElementById('meta2').remove();
    document.getElementById('meta3').remove();
    document.getElementById('meta4').remove();
    document.getElementById('meta5').remove();
    document.getElementById('meta6').remove();
  }
  return (
    <Helmet>
      {title ? <title>{title}</title> : <title>Law Firm Glušič - Legal Solutions tailored to your needs</title>}
      {title ? <meta property='og:title' content={title} /> : <meta property="og:title" content="Glušič odvetniška družba - Skupaj ustvarjamo nove zgodbe." />}
      {opis ? <meta name='description' content={opis} /> : <meta name="description" content="Skupaj ustvarjamo nove zgodbe."  />}
      {opis ? <meta property='og:description' content={opis} /> : <meta property="og:description" content="Skupaj ustvarjamo nove zgodbe."/> }
      <meta property='og:type' content='website' />
      <meta property='og:url' content={"https://glusic.si"+path} />
      {postFeaturedImage ?
        <meta name='twitter:card' content='summary_large_image' />
      : null}
      {postFeaturedImage ?
        <meta property='og:image' content={"https://glusic.si"+postFeaturedImage} /> : <meta property="og:image" content="https://www.glusic.si/assets/pictures/tanja_slika_spoznajte_nas.png" />
      }
    </Helmet>
  )
}

export default Meta
