import React from 'react';
import Radium from 'radium';
import {Link} from "react-router-dom";


class Slider extends React.Component {
	state = {

	};



	render() {
		return (
			<div style={styles.wrapper}>
				<div style={styles.text1}>{this.props.text1}</div>
				<div style={styles.text2}>{this.props.text2}</div>
				<Link onClick={()=>window.scrollTo(0,0)} to={`/view/${this.props.id}`} href="" style={styles.text5}><div>{this.props.text5}</div> <img alt="arrowRight" style={styles.img} src="../assets/icons/arrowRight.png" /></Link>
      </div>
		)
	}
}


const styles = {
	wrapper:{
		marginLeft:90,
		textAlign:"left",
		marginRight:70,
		'@media (max-width: 1040px)': {
			marginLeft:50,
			marginRight:30,
		},
		'@media (max-width: 490px)': {
			marginRight:30,
			marginLeft:30,
		},
		
	},
	text1:{
		color:"#F5A623",
		fontSize:20,
		fontWeight:600,
		height:30,
		marginBottom:25,
	},
	text2:{
		color:"#243353",
		fontSize:32,
		fontWeight:300,
		height:110,
		overflow:"hidden",
		maxWidth:580,
		marginBottom:25,
	},
	text3:{
		color:"#243353",
		fontSize:19,
		fontWeight:500,
		height:22,
		marginBottom:0,
	},
	text4:{
		color:"#F5A623",
		fontSize:20,
		fontWeight:400,
		height:30,
		marginBottom:30,
	},
	text5:{
		textDecoration:"none",
		color:"#F5A623",
		fontSize:20,
		display:"flex",
		fontWeight:400,
		height:30,
		transition:".2s",
		width:135,
		justifyContent:"space-between",
		alignItems:"center",
		":hover":{
			width:145,
		}
	},
	img:{
		height:18,
	}
};



export default Radium(Slider);