import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "../components/Slider";
import VrstaObjave from "../components/VrstaObjave";
import Radium from 'radium';
import MailForm from "../components/mailForm.js"


class Content extends React.Component {
  props;
	state = {
		objava:[
			{	
				avtorObjave: "",
				body: "",
				date: "",
				datumDogotka: "",
				excerpt: "",
				isEmpty: false,
				kategorija: "",
				keyWords: [],
				krajDogotka: "",
				minus: [],
				name: "",
				id:0,
				opis: "",
				plus: [],
				title: "",
			},
		],
		objava1:[
			{	
				avtorObjave: "",
				body: "",
				date: "",
				datumDogotka: "",
				excerpt: "",
				isEmpty: false,
				id:0,
				kategorija: "",
				keyWords: [],
				krajDogotka: "",
				minus: [],
				name: "",
				opis: "",
				plus: [],
				title: "",
			},
			{	
				avtorObjave: "",
				body: "",
				date: "",
				datumDogotka: "",
				excerpt: "",
				isEmpty: false,
				kategorija: "",
				keyWords: [],
				krajDogotka: "",
				minus: [],
				id:0,
				name: "",
				opis: "",
				plus: [],
				title: "",
			},
		],
		objava2:[
			{	
				avtorObjave: "",
				body: "",
				date: "",
				datumDogotka: "",
				excerpt: "",
				isEmpty: false,
				kategorija: "",
				keyWords: [],
				krajDogotka: "",
				minus: [],
				id:0,
				name: "",
				opis: "",
				plus: [],
				title: "",
			},
			{	
				avtorObjave: "",
				body: "",
				date: "",
				datumDogotka: "",
				excerpt: "",
				isEmpty: false,
				kategorija: "",
				keyWords: [],
				krajDogotka: "",
				minus: [],
				id:0,
				name: "",
				opis: "",
				plus: [],
				title: "",
			},
		],
	};


componentDidMount(){
		var self = this;
		var neki = [...this.props.fields];
    neki.reverse();
  	var arr1 = [];
  	var arr2 = [];
    for(var i=0; i<neki.length; i++){
      if(neki[i].kategorija === "Ne spreglejte" ||
      	neki[i].kategorija === "Dogodek" || 
      	neki[i].kategorija === "Novica"){
      	arr2.push(neki[i]); 
      } 
    }
    for(i=0; i<neki.length; i++){
      if(neki[i].kategorija === "Prispevek" || neki[i].kategorija === "Novica"){
      	arr1.push(neki[i]); 
      } 
    }
  	self.setState({objava1: arr1, objava2: arr2});
	}

	render() {
		return (
			<div style={styles.content}>


				<div style={styles.topPicture}>
					<div style={styles.topPictureBox}>
						<div style={styles.topPictureBoxTitle1}>Legal Solutions tailored to your needs</div>
						<div style={styles.topPictureBoxTitle2}>Creating new stories together.</div>
						<Link onClick={()=>window.scrollTo(0,0)} to="/Odvetniki" style={styles.box_gumb}>WHO WE ARE</Link>
					</div>
				</div>

				<div style={styles.box}>
					<div style={styles.box_center}>
						<div style={styles.box_Title1} >Legal Solutions tailored to your needs</div>
						<div style={styles.box_Title2} >Creating new stories together.</div>
						<Link onClick={()=>window.scrollTo(0,0)} to="/Odvetniki" style={styles.box_gumb}>WHO WE ARE</Link>
					</div>
				</div>




				<div style={styles.noviceSliders}>
					<Slider {...this.state} />
				</div>





				<div style={styles.popupWrapper}>
					<div style={styles.popupWrapper_besedilo}>
						<div style={styles.popupWrapper_naslov}>
						Your case always comes first.
						</div>
					</div>
					

					<div style={styles.popupWrapper_2_wrapper}>
						<div style={styles.popupWrapper_2}>
							<div style={styles.popupWrapper_1_besedilo}>
								<div style={styles.popupWrapper_1_besedilo_line1_wrapper}>
									<img style={styles.popupWrapper_besedilo_img} src = "../assets/icons/ikona_citat_copy_2.svg" alt = "ikona_citat" />
									<div style={styles.popupWrapper_besedilo_line1}>We work hand in  hand with our clients.</div>
								</div>
								<div style={styles.popupWrapper_besedilo_ime}>Tanja Glušič</div>
								<div style={styles.popupWrapper_besedilo_naziv}>Attorney, Founding Partner</div>
							</div>
						</div>
					</div>

					<div style={styles.popupWrapper_1_wrapper}>
						<div style={styles.popupWrapper_1}>
							<div style={styles.popupWrapper_1_besedilo}>
								<div style={styles.popupWrapper_1_besedilo_line1_wrapper}>
									<img style={styles.popupWrapper_besedilo_img} src = "../assets/icons/ikona_citat_copy_2.svg" alt = "ikona_citat" />
									<div style={styles.popupWrapper_besedilo_line1}>I am always looking for solutions.</div>
								</div>
								<div style={styles.popupWrapper_besedilo_ime}>Janina Košnik Vrtač</div>
								<div style={styles.popupWrapper_besedilo_naziv}>Attorney</div>
							</div>
						</div>
					</div>

					


				</div>
				<div style={styles.wrapper}>
					{this.state.objava2.map((objava2, i) => <VrstaObjave {...this.state.objava2[i]} key={i} />)}

					{this.state.button ? 
						<div style={styles.content_gumb_positon}>
							<button style={styles.content_gumb}>POKAŽI VEČ</button>
						</div>
						:
						null
					}
				</div>
				<MailForm />
			</div>
		);
	}
}






const styles = {
	//topPicture and box
	content:{
		position:"relative",
		backgroundColor:"#fff",
		width: "100%",
	},
	topPicture: {
		position:"relative",

		backgroundImage: "linear-gradient(90deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0.5) 100%), url(../assets/pictures/homePageHeader.jpg)",
		backgroundPosition: "40% 40%",
		backgroundSize:"cover",
		height:"60vh",
		width:"100vw",
		minHeight:470,
		maxHeight:800,
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		'@media (max-width: 750px)': {
			height:"65vh",
			backgroundPosition: "30% 40%",
		},
		'@media (max-width: 680px)': {
			height:"60vh",
			backgroundPosition: "40% 40%"
		},
		'@media (max-width: 550px)': {
			backgroundImage: "url(../assets/pictures/homePageHeader.jpg)",
		},
		'@media (max-width: 520px)': {
				minHeight:300,
				height:"60vh",
				backgroundPosition: "10% 40%"
		},
		'@media (max-width: 420px)': {
				backgroundPosition: "20% 40%"
		},
},

topPictureBox:{
	width: 440,
	position: "relative",
	marginRight:"0",
	'@media (min-width: 1500px)': {
		marginRight:"calc((100vw - 1080px)/2)",
	},
	
	'@media (max-width: 830px)': {
		width: 400,

	},
	'@media (max-width: 750px)': {
		width: 320,

	},
	'@media (max-width: 520px)': {
			display: "none"
		},

},

topPictureBoxTitle1: {
	fontSize:53,
	fontWeight:300,
	color:"#162A75",
	paddingTop:30,
	paddingBottom:30,
	
	'@media (max-width: 830px)': {
		fontSize:45,
	},
	'@media (max-width: 750px)': {
		fontSize:35,
	},
	'@media (max-width: 400px)': {
		fontSize:30,
		paddingTop:20,
		paddingBottom:20,
	},
},
topPictureBoxTitle2: {
	fontSize:20,
	fontWeight:300,
	color:"#162A75",
	
	'@media (max-width: 500px)': {
		fontSize:17,
	},
},
focusWrapper_gumb:{
		marginTop: 40,
		width:200,
		textAlign:"center",
		backgroundColor: "#F89C2E",
		border: "none",
		cursor:"pointer",
		lineHeight:3,
		whiteSpace:"noWrap",
		color: "#fff",
		borderRadius: 4,
		fontSize: 16,
		fontWeight: 300,

		letterSpacing: 2,
		transition: "0.2s",
		
		'@media (max-width: 640px)': {
			//margin:"0 auto",
			marginTop:40,
		},
		'@media (max-width: 340px)': {
			width:170,

		},
		":hover":{
			boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
			transform: "translateY(-2px) scaleX(1.02) scaleY(1.02)",
		},

		":active":{
			outline: "none",
			transform: "translateY(-1px)",

		},
		":focus":{
			outline: "none",
			

		},

	},
	
	box:{
		display: "none",
		'@media (max-width: 520px)': {
			display: "flex",
			backgroundColor: "#F5F5F5",
			justifyContent: "center",
			alignItems: "center"
		},
	},
	box_center:{
		width: "calc(100% - 50px)",
		margin: "30px auto",
		maxWidth:"100%",
		
		'@media (max-width: 420px)': {
			maxWidth:290,
		},
		'@media (max-width: 360px)': {
			maxWidth:250,
		},
	},
	box_Title1:{
		fontSize:39,
		fontWeight:300,
		color:"#162A75",
		paddingBottom:30,
		'@media (max-width: 420px)': {
			fontSize:34,
		},
		'@media (max-width: 360px)': {
			fontSize:30,
		},
	},
	box_Title2:{
		fontSize:20,
		fontWeight:300,
		color:"#162A75",
		'@media (max-width: 360px)': {
			fontSize:15,
		},
	},
	box_gumb:{
		display:"block",
		textDecoration:"none",
		marginTop: 40,
		width:200,
		textAlign:"center",
		backgroundColor: "#F89C2E",
		border: "none",
		cursor:"pointer",
		lineHeight:3,
		whiteSpace:"noWrap",
		color: "#fff",
		borderRadius: 4,
		fontSize: "15.84",
		fontWeight: "300",
		letterSpacing: 2,
		'@media (max-width: 360px)': {
			width:180,
			letterSpacing: 1,
		},
	},

	
	

	focusWrapper_besedilo_naslov_h1:{
		fontSize: 48,
		'@media (max-width: 1200px)': {
					fontSize: 38
			},
			'@media (max-width: 1020px)': {
					fontSize: 35
			},
			 '@media (max-width: 925px)': {
					fontSize: 30
			},
			'@media (max-width: 650px)': {
					fontSize: 25
			}
	},
	focusWrapper_besedilo_naslov_h2:{
		fontSize: 21.12,
		fontWeight: 400,
		marginTop: 30,
		'@media (max-width: 1065px)': {
					fontSize: 20
			},
			'@media (max-width: 925px)': {
				marginTop: 20,
					fontSize: 15
			}
	},

	noviceSliders:{
		margin: "60px 0px",
		textAlign: "center",
		fontSize: 30
	},
	popupWrapper:{
		display: "flex",  
			flexFlow: "row wrap",
			textAlign: "center",
			marginBottom: 50
				
	},
	popupWrapper_besedilo:{
		width: "100%",
		display: "flex",
		justifyContent: "center",
		padding: "35px 0px",
		backgroundColor: "#142153",
		'@media (max-width: 1200px)': {
					padding: "20px 10px",
			},
	},
	popupWrapper_naslov:{
		fontSize: 41,
		fontWeight: "200",
		color: "#FFF",
		padding: "40px 0px",
		fontStyle: "normal",
		
		'@media (max-width: 700px)': {
				fontSize: 35,
		},
		'@media (max-width: 470px)': {
				fontSize: 25,
		},
	},














	//popupWrapper

	popupWrapper_1_wrapper:{
		width: "50%",
		background:"#F1F2F5",
		'@media (max-width: 850px)': {
			width: "100%",
		},
	},
	popupWrapper_2_wrapper:{
		width: "50%",
		background:"#F3F3F3",
		'@media (max-width: 850px)': {
			width: "100%",
		},

	},
	popupWrapper_1:{
		maxWidth:650,
		backgroundImage: "url(../assets/pictures/SLIKAJANINA_preurejena.jpg)",
		height: 400,
		width: "100%",
		float:"left",
		backgroundSize:"cover",
		backgroundPosition: "100% 0%",
		'@media (max-width: 1250px)': {
			backgroundPosition: "50% 0%",
		},
		'@media (max-width: 850px)': {
			backgroundPosition: "80% 0%",
			float:"none",
			margin:"0px auto",
		},
		'@media (max-width: 640px)': {
			backgroundPosition: "40% 0%",

		},
		'@media (max-width: 540px)': {
			backgroundPosition: "60% 0%",
		},
		'@media (max-width: 400px)': {
			height: 300,
			backgroundPosition: "60% 0%",
		},
		'@media (max-width: 360px)': {
			height: 250,
			backgroundPosition: "40% 0%",
		},
	},
	popupWrapper_2:{
		backgroundImage: "url(../assets/pictures/SLIKATANJA_preurejena.jpg)",
		height: 400,
		maxWidth:650,
		float:"right",
		backgroundSize:"cover",
		backgroundPosition: "100% 0%",
		width: "100%",
		'@media (max-width: 1250px)': {
			backgroundPosition: "0% 0%",
		},
		'@media (max-width: 1000px)': {
				backgroundPosition: "30% 0%",
		},
		'@media (max-width: 850px)': {
			backgroundPosition: "0% 0%",
			float:"none",
			margin:"0px auto",
			maxWidth:650,
		},
		'@media (max-width: 540px)': {
			backgroundPosition: "40% 0%",
		},
		'@media (max-width: 400px)': {
			height: 300,
			backgroundPosition: "20% 0%",
		},
		'@media (max-width: 360px)': {
			height: 250,
			backgroundPosition: "0% 0%",
		},
	},


	popupWrapper_1_besedilo:{
		width: "42%",
		textAlign: "left",
		position: "relative",
		top: "15%",
		left: "12%",
		'@media (max-width: 1250px)': {
			width:280,
		},
		'@media (max-width: 1000px)': {
			width:250,
		},
		'@media (max-width: 540px)': {
			width:200,
		},
		'@media (max-width: 400px)': {
			width:180,
		},
	},
	popupWrapper_1_besedilo_line1_wrapper:{
		display:"flex",
		marginLeft: -34,
		fontSize:25,
		alignItems:"flex-start",
		color:"#243353",
	},
	popupWrapper_besedilo_img:{
		marginRight:10,
		marginTop:3,
		'@media (max-width: 1000px)': {
				width:20,
		},
		'@media (max-width: 450px)': {
			width:17,
			marginTop:1,
		},
	},








	popupWrapper_besedilo_line1:{
		fontSize: 25,
		fontWeight: 400,
		
		color: "#243353",
		'@media (max-width: 1000px)': {
				fontSize: 22
		},
		'@media (max-width: 540px)': {
			fontSize:20,
		},
		'@media (max-width: 450px)': {
				fontSize: 18,
		},
	},
	popupWrapper_besedilo_ime:{
		fontSize: 19,
		fontWeight: "500",
		color: "#F5A623",
		marginTop: 15,
		marginBottom:5,
		'@media (max-width: 1000px)': {
			fontSize: 15
		},
		'@media (max-width: 450px)': {
			fontSize: 12,
		},
	},
	popupWrapper_besedilo_naziv:{
		fontSize: 15.2,
		color: "#243353",
		fontStyle: "italic",
		margin: 0,
		'@media (max-width: 1000px)': {
				fontSize: 13
			},
			// '@media (max-width: 840px)': {
			// 		fontSize: 15.2
			// },
	},


	//popupWrapperEnd




	wrapper:{
		position:"relative",
		backgroundColor:"#fff",
		width: "calc(100% - 60px)",
		maxWidth:1000,
		marginBottom:50,
		marginTop:"0px",
		marginLeft:"auto",
		marginRight:"auto",
	},





	objave_elementi:{
		margin: "60px 0px",
		textAlign: "center",
		fontSize: 30

	},
	prijava:{
		width: "100%",
		backgroundColor: "grey",
		height: "30vh",
		marginBottom: 8,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: "linear-gradient(to bottom right,rgba(36, 51, 83, 0.7),rgba(36, 51, 83, 0.7)), url(../assets/pictures/iStock_000027753701_Medium-e1455794128429.png)",
		backgroundSize: "cover",
		'@media (max-width: 1200px)': {
					height: "25vh"
			},
		'@media (max-width: 320px)': {
				height: "30vh"
		},
	},
	prijava_form:{
		width: "100%",
		textAlign:"center",
		position: "relative",
		top: "-10%",
		fontSize: 41,
		fontWeight: "300",
		color: "#FFF",
		diplay: "block",
		'@media (max-width: 1150px)': {
			fontSize: 35,
			},
			'@media (max-width: 820px)': {
			fontSize: 30,
			},
			'@media (max-width: 700px)': {
			fontSize: 25,
			top: 0
			},
			'@media (max-width: 320px)': {
			fontSize: 25,
			top: "-5%"
			},
	},
	prijava_list:{
		diplay: "flex",
		justifyContent: "center",
		position: "relative",
		top: 30,
		'@media (max-width: 1000px)': {
			top: 20
			},
			'@media (max-width: 700px)': {
			top: 10
			},
	},
	contacts_form_inputWraper_design:{
		borderRadius: 4,
		width: "28%",
		padding: "10px 0px 10px 15px",
		display: "inline-block",
		position: "relative",
		left: -13,
		backgroundColor: "#FFF",
		'@media (max-width: 1150px)': {
			padding: "5px 0px 5px 15px",
			},
			'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px"
			},
			'@media (max-width: 820px)': {
			width: "32%"
			},
			'@media (max-width: 700px)': {
			display: "block",
			margin: "0 auto",
			left: 0,
			marginBottom: 10,
			width: "40%"
			},
			'@media (max-width: 600px)': {
			width: "50%"
			},
	},
	contacts_form_input:{
		fontSize: 19,
		fontWeight: "300",
		border: "none",
		borderLeft: "2px solid #F5A623",
		padding: "10px 0px 10px 15px",
		width: "95%",
		outline: "none",
		'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px",
			fontSize: 17,
			},	
			'@media (max-width: 820px)': {
			padding: "3px 0px 3px 10px",
			fontSize: 15,
			},
	},

	/*prijava_input:{
		borderRadius: 4,
		fontSize: 19,
		fontWeight: "300",
		width: "29%",
		padding: "17 0 17 40",
		position: "relative",
		left: -20,
		outline: "none",
		boxSizing: "border-box",
		borderLeft: "none"
	},*/
	prijava_gumb:{
		marginLeft: "10px",
		backgroundColor: "#F89C2E",
		border: "none",
		padding: "10px 75px",
		color: "#fff",
		borderRadius: 4,
		fontSize: 15.84,
		letterSpacing: 2,
		fontWeight: "300",
		transition: "all .2s",
		cursor: "pointer",
		'@media (max-width: 1000px)': {
			padding: "10px 65px",
			fontSize: 15
			},
			'@media (max-width: 900px)': {
			padding: "5px 35px",
			fontSize: 13
			},
			'@media (max-width: 700px)': {
			marginLeft: 0,
			margin: "0px auto"
			},
		":hover":{
			boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
			transform: "translateY(-2px scaleX(1.02) scaleY(1.02)",
		},

		":active":{
			outline: "none",
			transform: "translateY(-1px)",

		},
		":focus":{
			outline: "none",
			

		},
	},

	massagePrijava:{
		color:"#fff",
		fontWeight:300,
		fontSize:30,
		marginTop:25,
		marginBottom:-10,
	},


};

export default Radium(Content);